import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/public/NavBar';
import Home from './components/public/Home';
import About from './components/public/About';
import Contact from './components/public/Contact';
import FAQPage from './components/public/FAQ_Page';
import TnC from './components/public/T&C';
import PrivacyPolicy from './components/public/PrivacyPolicy';
import EmailVerified from './components/public/verifyEmail/EmailVerified';
import EmailUnverified from './components/public/verifyEmail/EmailUnverified';


function App() {
  return (
    <Router>
      <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about_us" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/tnc" element={<TnC />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/verify-email/verified" element={<EmailVerified />} />
              <Route path="/verify-email/unverified" element={<EmailUnverified />} />
              {/* Redirect from root URL to /. This is optional */}
              <Route path="/" element={<Navigate replace to="/" />} />
            </Routes>
    </Router>
  );
}

export default App;
