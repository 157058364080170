import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion, useInView, useAnimation } from 'framer-motion';

const UseCases = () => {
  // Refs and animations for scroll-triggered animations
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, threshold: 0.1 });
  const mainControls = useAnimation();
  
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  // Animated icons for better visual appeal
  const ForecastAnimation = () => {
    return (
      <div className="animation-wrapper">
        <svg width="100%" height="100%" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path 
            d="M50 150 L100 100 L150 130 L200 70 L250 110" 
            stroke="var(--ai-teal)" 
            strokeWidth="3" 
            strokeLinecap="round"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
            fill="none"
          />
          <motion.path 
            d="M50 150 L100 100 L150 130 L200 70 L250 110" 
            stroke="rgba(0, 201, 255, 0.2)" 
            strokeWidth="12" 
            strokeLinecap="round"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
            fill="none"
          />
          
          {/* Forecast dotted line */}
          <motion.path 
            d="M150 130 L200 150 L250 90" 
            stroke="var(--ai-primary)" 
            strokeWidth="3" 
            strokeLinecap="round"
            strokeDasharray="6 4"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1, delay: 1.7 }}
            fill="none"
          />
          
          {/* Data points */}
          {[
            { x: 50, y: 150 }, 
            { x: 100, y: 100 }, 
            { x: 150, y: 130 }, 
            { x: 200, y: 70 }, 
            { x: 250, y: 110 }
          ].map((point, index) => (
            <motion.circle
              key={`point-${index}`}
              cx={point.x}
              cy={point.y}
              r="6"
              fill="var(--ai-teal)"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3, delay: 0.7 + index * 0.15 }}
            />
          ))}
          
          {/* Forecast points */}
          {[
            { x: 200, y: 150 },
            { x: 250, y: 90 }
          ].map((point, index) => (
            <motion.circle
              key={`forecast-${index}`}
              cx={point.x}
              cy={point.y}
              r="6"
              fill="var(--ai-primary)"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3, delay: 2 + index * 0.15 }}
            />
          ))}
          
          {/* Time arrow */}
          <motion.line
            x1="30"
            y1="170"
            x2="270"
            y2="170"
            stroke="#999"
            strokeWidth="2"
            initial={{ width: 0 }}
            animate={{ width: 240 }}
            transition={{ duration: 1 }}
          />
          <motion.path
            d="M260 165 L270 170 L260 175"
            stroke="#999"
            strokeWidth="2"
            fill="none"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9 }}
          />
          
          {/* Current point indicator */}
          <motion.line
            x1="150"
            y1="40"
            x2="150"
            y2="170"
            stroke="#fff"
            strokeWidth="1"
            strokeDasharray="4 2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ delay: 1.2 }}
          />
          <motion.text
            x="150"
            y="30"
            textAnchor="middle"
            fill="#fff"
            fontSize="12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.3 }}
          >
            TODAY
          </motion.text>
        </svg>
      </div>
    );
  };

  const InventoryAnimation = () => {
    return (
      <div className="animation-wrapper">
        <svg width="100%" height="100%" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          {/* Safety stock zone */}
          <motion.rect
            x="30"
            y="130"
            width="240"
            height="20"
            fill="rgba(255, 209, 102, 0.2)"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
          <motion.text
            x="35"
            y="145"
            fill="#FFD166"
            fontSize="10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            Safety Stock
          </motion.text>
          
          {/* Traditional inventory - high fluctuation */}
          <motion.path
            d="M30 110 L60 70 L90 100 L120 50 L150 120 L180 60 L210 90 L240 40 L270 80"
            stroke="#FF6B6B"
            strokeWidth="3"
            fill="none"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, delay: 0.5 }}
          />
          
          {/* Optimized inventory - smoother */}
          <motion.path
            d="M30 90 L60 80 L90 85 L120 75 L150 80 L180 70 L210 75 L240 65 L270 70"
            stroke="#00F5A0"
            strokeWidth="3"
            fill="none"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, delay: 1.5 }}
          />
          
          {/* Demand line */}
          <motion.path
            d="M30 100 L60 105 L90 110 L120 100 L150 105 L180 100 L210 105 L240 95 L270 100"
            stroke="white"
            strokeWidth="2"
            strokeDasharray="5 3"
            fill="none"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 2.5 }}
          />
          
          {/* Stockout indicators */}
          {[
            { x: 120, y: 50 },
            { x: 240, y: 40 }
          ].map((point, index) => (
            <motion.g key={`stockout-${index}`}>
              <motion.circle
                cx={point.x}
                cy={point.y}
                r="8"
                fill="rgba(255, 107, 107, 0.3)"
                initial={{ scale: 0 }}
                animate={{ scale: [0, 1.5, 1] }}
                transition={{ 
                  duration: 0.8,
                  delay: 3 + index * 0.3,
                  times: [0, 0.6, 1]
                }}
              />
              <motion.circle
                cx={point.x}
                cy={point.y}
                r="4"
                fill="#FF6B6B"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 3 + index * 0.3 }}
              />
            </motion.g>
          ))}
          
          {/* Time axis */}
          <motion.line
            x1="30"
            y1="170"
            x2="270"
            y2="170"
            stroke="#999"
            strokeWidth="2"
            initial={{ width: 0 }}
            animate={{ width: 240 }}
            transition={{ duration: 1 }}
          />
          <motion.path
            d="M260 165 L270 170 L260 175"
            stroke="#999"
            strokeWidth="2"
            fill="none"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9 }}
          />
          
          {/* Legend */}
          <motion.g
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <rect x="30" y="20" width="12" height="3" fill="#FF6B6B" />
            <text x="45" y="23" fontSize="10" fill="#E0E0E0">Traditional</text>
            
            <rect x="115" y="20" width="12" height="3" fill="#00F5A0" />
            <text x="130" y="23" fontSize="10" fill="#E0E0E0">ARAMA Optimized</text>
            
            <rect x="230" y="20" width="12" height="3" fill="#FFFFFF" strokeDasharray="3,2" />
            <text x="245" y="23" fontSize="10" fill="#E0E0E0">Demand</text>
          </motion.g>
        </svg>
      </div>
    );
  };

  return (
    <section ref={sectionRef} className="use-cases-section py-6" style={{ background: 'var(--ai-dark)', position: 'relative' }}>
      <Container>
        <motion.div
          initial="hidden"
          animate={mainControls}
          variants={containerVariants}
        >
          <motion.div variants={itemVariants} className="text-center mb-5">
            <h2>Our <span style={{ color: 'var(--ai-teal)' }}>Use Cases</span></h2>
            <div className="title-underline mx-auto"></div>
            <p className="mt-4 mx-auto" style={{ maxWidth: '800px' }}>
              ARAMA's AI-powered solutions transform your business with intelligent 
              demand forecasting and inventory optimization, giving you a competitive edge.
            </p>
          </motion.div>

          {/* Use Case Cards */}
          <Row className="gy-4 gx-5">
            {/* Demand Forecasting */}
            <Col lg={6}>
              <motion.div 
                variants={itemVariants}
                className="use-case-card h-100"
                whileHover={{ 
                  y: -5, 
                  boxShadow: '0 10px 30px rgba(110, 17, 244, 0.15)'
                }}
                transition={{ type: "spring", stiffness: 500 }}
              >
                <div className="title-wrapper">
                  <div className="card-icon">
                    <i className="bi bi-graph-up-arrow"></i>
                  </div>
                  <h3>Demand Forecasting</h3>
                </div>
                <div className="animation-container">
                  <ForecastAnimation />
                </div>
                <p>
                  ARAMA's intelligent forecasting algorithms analyze historical data, market trends, and 
                  external factors to predict future demand with exceptional accuracy.
                </p>
                <ul className="feature-list">
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Multi-factor analysis for precise predictions
                  </li>
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Self-improving forecasts over time
                  </li>
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Real-time adjustment to market changes
                  </li>
                </ul>
              </motion.div>
            </Col>

            {/* Inventory Optimization */}
            <Col lg={6}>
              <motion.div 
                variants={itemVariants}
                className="use-case-card h-100"
                whileHover={{ 
                  y: -5, 
                  boxShadow: '0 10px 30px rgba(0, 245, 160, 0.15)'
                }}
                transition={{ type: "spring", stiffness: 500 }}
              >
                <div className="title-wrapper">
                  <div className="card-icon">
                    <i className="bi bi-boxes"></i>
                  </div>
                  <h3>Inventory Optimization</h3>
                </div>
                <div className="animation-container">
                  <InventoryAnimation />
                </div>
                <p>
                  Our AI dynamically optimizes inventory levels across your supply chain, reducing 
                  carrying costs while ensuring you meet customer demand.
                </p>
                <ul className="feature-list">
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Smart safety stock calculations
                  </li>
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Prevent stockouts while minimizing excess
                  </li>
                  <li>
                    <motion.span 
                      className="icon-wrapper"
                      whileHover={{ scale: 1.2, rotate: 5 }}
                    >
                      <i className="bi bi-check-circle-fill"></i>
                    </motion.span>
                    Reduce carrying cost and free up capital
                  </li>
                </ul>
              </motion.div>
            </Col>
          </Row>
        </motion.div>
      </Container>

      {/* CSS for this component */}
      <style jsx>{`
        .use-cases-section {
          padding: 100px 0;
          overflow: hidden;
        }
        
        .use-case-card {
          background: rgba(25, 26, 36, 0.7);
          border: 1px solid rgba(110, 17, 244, 0.2);
          border-radius: 16px;
          padding: 30px;
          transition: all 0.3s ease;
          position: relative;
          backdrop-filter: blur(10px);
        }
        
        .title-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        
        .card-icon {
          background: linear-gradient(135deg, var(--ai-primary) 0%, var(--ai-teal) 100%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          flex-shrink: 0;
        }
        
        .card-icon i {
          font-size: 1.5rem;
          color: white;
        }
        
        .use-case-card h3 {
          color: white;
          font-size: 1.5rem;
          margin-bottom: 0;
        }
        
        .animation-container {
          height: 200px;
          margin: 20px 0;
          overflow: hidden;
        }
        
        .animation-wrapper {
          width: 100%;
          height: 100%;
        }
        
        .feature-list {
          list-style: none;
          padding: 0;
          margin-top: 20px;
        }
        
        .feature-list li {
          display: flex;
          align-items: flex-start;
          margin-bottom: 12px;
          color: #e0e0e0;
        }
        
        .icon-wrapper {
          display: inline-flex;
          color: var(--ai-teal);
          margin-right: 10px;
        }
      `}</style>
    </section>
  );
};

export default UseCases; 