import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Footer from './Footer';
import '../../custom.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ContactUsForm from './ContactUsForm';
import Features from './Features';
import UseCases from './UseCases';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive';

const Home = () => {
    // State for handling modal visibility
    const [showModal, setShowModal] = useState(false);
    
    // Add responsive media query hook
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    
    const heroRef = useRef(null);
    
    // Initialize AOS animation library
    useEffect(() => {
        AOS.init({
            duration: isMobile ? 800 : 1000,
            once: isMobile, // On mobile, only animate once for better performance
            mirror: !isMobile,
            disable: 'phone' // Disable complex animations on phone for performance
        });
        
        return () => {};
    }, [isMobile]);

    // Functions to open and close the modal
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Generate random data points for visualization
    const generateDataPoints = (count) => {
        const points = [];
        for (let i = 0; i < count; i++) {
            points.push({
                x: Math.random() * 100,
                y: Math.random() * 100,
                size: Math.random() * (isMobile ? 4 : 5) + (isMobile ? 1 : 2),
                delay: Math.random() * (isMobile ? 3 : 5)
            });
        }
        return points;
    };
    
    // Reduce data points on mobile for better performance
    const dataPoints = generateDataPoints(isMobile ? 10 : isTablet ? 25 : 40);

    return (
        <div className="modern-ai-theme">
            {/* Modern grid background with lines */}
            <div className="grid-lines"></div>
            
            {/* Hero section */}
            <section className="modern-hero" ref={heroRef}>
                <div className="hero-blob"></div>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} md={12} className="hero-content text-center text-lg-start mb-4 mb-lg-0">
                            <motion.div
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                            >
                                <h1>
                                    <span className="main-heading">ARAMA</span>
                                </h1>
                                <h2 className="mb-4" style={{ 
                                    fontSize: isMobile ? '1.6rem' : isTablet ? '2rem' : '2.25rem',
                                    lineHeight: isMobile ? '1.3' : '1.4'
                                }}>
                                    Your 24/7 <span style={{ color: 'var(--ai-teal)' }}>AI business advisor</span>
                                </h2>
                                <p className="mb-4" style={{ 
                                    fontSize: isMobile ? '1rem' : '1.1rem',
                                    lineHeight: isMobile ? '1.5' : '1.6',
                                    maxWidth: isMobile ? 'none' : '90%' 
                                }}>
                                    Smart business guidance, strategic foresight, and institutional knowledge preservation in plain language from day one - <strong>ZERO TECHNICAL SKILLS REQUIRED</strong>.
                                </p>
                                <div className="d-flex gap-3 mt-4 justify-content-center justify-content-lg-start">
                                    <motion.button
                                        className="ai-button"
                                        onClick={handleOpenModal}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.97 }}
                                        style={{ 
                                            padding: isMobile ? '12px 20px' : '12px 24px',
                                            fontSize: isMobile ? '0.95rem' : '1rem',
                                            minWidth: isMobile ? '180px' : '200px',
                                            touchAction: 'manipulation'
                                        }}
                                    >
                                        <i className="bi bi-lightning me-2"></i>
                                        Boost Your Business Now!
                                    </motion.button>
                                </div>
                            </motion.div>
                        </Col>
                        <Col lg={6} md={12}>
                            <motion.div
                                className="position-relative"
                                style={{ 
                                    height: isMobile ? '250px' : isTablet ? '350px' : '500px',
                                    marginTop: isMobile ? '10px' : '0',
                                    overflow: 'hidden'
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5 }}
                            >
                                {/* 3D Data Visualization */}
                                <div className="data-container" style={{ touchAction: 'pan-x pan-y' }}>
                                    {dataPoints.map((point, index) => (
                                        <motion.div
                                            key={index}
                                            className="data-point"
                                            style={{
                                                left: `${point.x}%`,
                                                top: `${point.y}%`,
                                                width: `${point.size}px`,
                                                height: `${point.size}px`
                                            }}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: [0, 1, 0] }}
                                            transition={{
                                                duration: 3,
                                                delay: point.delay,
                                                repeat: Infinity,
                                                repeatType: 'loop'
                                            }}
                                        />
                                    ))}
                                    
                                    {/* Floating 3D Elements - smaller on mobile */}
                                    <motion.div
                                        className="floating-3d"
                                        style={{
                                            position: 'absolute',
                                            width: isMobile ? '100px' : isTablet ? '150px' : '200px',
                                            height: isMobile ? '100px' : isTablet ? '150px' : '200px',
                                            borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
                                            background: 'var(--ai-gradient-2)',
                                            opacity: isMobile ? 0.6 : 0.7,
                                            filter: isMobile ? 'blur(6px)' : 'blur(10px)',
                                            zIndex: 1
                                        }}
                                    />
                                    
                                    <motion.div
                                        className="floating-3d"
                                        style={{
                                            position: 'absolute',
                                            width: isMobile ? '60px' : isTablet ? '90px' : '120px',
                                            height: isMobile ? '60px' : isTablet ? '90px' : '120px',
                                            right: '20%',
                                            top: '20%',
                                            borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
                                            background: 'var(--ai-gradient-1)',
                                            opacity: isMobile ? 0.5 : 0.6,
                                            filter: isMobile ? 'blur(5px)' : 'blur(8px)',
                                            zIndex: 1,
                                            animationDelay: '2s'
                                        }}
                                    />
                                    
                                    {/* Mesh/Grid Effect - with adjusted sizing for mobile */}
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: 'radial-gradient(circle at 10px 10px, rgba(255,255,255,0.05) 2px, transparent 0)',
                                        backgroundSize: isMobile ? '20px 20px' : isTablet ? '30px 30px' : '40px 40px',
                                        zIndex: 0
                                    }} />
                                </div>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* Stats Section with counter animation */}
            <section className="py-5 stats-section bg-dark" style={{ 
                background: 'var(--ai-dark)',
                padding: isMobile ? '40px 0' : '50px 0' 
            }}>
                <Container>
                    <Row className="text-center">
                        <Col>
                            <div className="mb-4 mb-md-5">
                                <h2 className="text-center" data-aos="fade-up" style={{ 
                                    fontSize: isMobile ? '1.6rem' : isTablet ? '2rem' : '2.25rem',
                                    marginBottom: isMobile ? '15px' : '20px' 
                                }}>
                                    Transforming <span style={{ color: 'var(--ai-teal)' }}>Decisions</span> Into Business Growth
                                </h2>
                                <p data-aos="fade-up" data-aos-delay="100" style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>Designed for business owners, not data scientists - no technical skills needed</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={4} sm={6} xs={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "50" : "100"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-lightning-charge" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3 style={{ fontSize: isMobile ? '1.3rem' : '1.5rem' }}>Immediate, Actionable Guidance</h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>Get crystal-clear guidance from day one, blending your internal data with external signals for better decisions</p>
                            </motion.div>
                        </Col>
                        <Col md={4} sm={6} xs={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "100" : "200"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-database-check" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3 style={{ fontSize: isMobile ? '1.3rem' : '1.5rem' }}>
                                    Strata<span className="nowrap">Memory™</span><br />
                                    Engine
                                </h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>A sophisticated knowledge system that continuously learns from you, <strong>for you only</strong>, and preserves critical organizational insights</p>
                            </motion.div>
                        </Col>
                        <Col md={4} sm={6} xs={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "150" : "300"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-chat-text" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3 style={{ fontSize: isMobile ? '1.3rem' : '1.5rem' }}>
                                    Plain-Language<br />
                                    Advisor
                                </h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>Zero technical expertise needed. Ask questions in everyday language and get clear, actionable guidance - no coding, no data skills, no problem.</p>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* How It Works Section - Simple Steps */}
            <section className="py-5" style={{ 
                background: 'var(--ai-dark)',
                padding: isMobile ? '40px 0' : '50px 0'
            }}>
                <Container>
                    <Row className="mb-4 mb-md-5">
                        <Col className="text-center">
                            <h2 data-aos="fade-up" style={{ fontSize: isMobile ? '1.6rem' : '2.25rem' }}>
                                How ARAMA <span style={{ color: 'var(--ai-teal)' }}>Works</span>
                            </h2>
                            <p data-aos="fade-up" data-aos-delay="100" style={{ 
                                fontSize: isMobile ? '0.95rem' : '1rem',
                                lineHeight: isMobile ? '1.4' : '1.5'
                            }}>Your business brain in the background - capturing, learning, and advising</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "50" : "100"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="process-number">01</div>
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-people" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3>Connect Your Data</h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>We seamlessly integrate with your existing data sources to create immediate value by blending internal data with external market signals.</p>
                            </motion.div>
                        </Col>
                        <Col lg={4} md={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "100" : "200"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="process-number">02</div>
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-chat-dots" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3>Ask Questions</h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>Simply ask business questions like "Should I order extra stock before the holiday?" and receive clear, actionable answers immediately.</p>
                            </motion.div>
                        </Col>
                        <Col lg={4} md={12} className="mb-4">
                            <motion.div 
                                className="glass-card h-100"
                                whileHover={{ y: isMobile ? -5 : -10 }}
                                data-aos="fade-up"
                                data-aos-delay={isMobile ? "150" : "300"}
                                style={{ 
                                    padding: isMobile ? '20px 15px' : '25px 20px',
                                    marginBottom: isMobile ? '10px' : '0'
                                }}
                            >
                                <div className="process-number">03</div>
                                <div className="feature-icon text-center mb-3">
                                    <i className="bi bi-graph-up-arrow" style={{ fontSize: '2.5rem', color: 'var(--ai-teal)' }}></i>
                                </div>
                                <h3>Grow Your Business</h3>
                                <p style={{ 
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    lineHeight: isMobile ? '1.4' : '1.5'
                                }}>As your business evolves, our StrataMemory™ engine adapts to your unique patterns, continuously improving its strategic recommendations.</p>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* Features Section with Interactive Cards */}
            <Features 
                feature1Title="Decision Intelligence"
                feature1Description="Unlike tools that only show historical trends, ARAMA tells you what to do next, helping you cut costs and boost revenue for more profit."
                feature1Icon="bi bi-graph-up"
                feature2Title="StrataMemory™"
                feature2Description="Our proprietary engine captures your business journey and context, creating a self-improving system that learns what works for your unique organization."
                feature2Icon="bi bi-cpu"
                feature3Title="Plain-Language Advisor"
                feature3Description="Zero technical expertise needed. Ask questions in everyday language and get clear, actionable guidance - no coding, no data skills, no problem."
                feature3Icon="bi bi-chat-text"
            />
            
            {/* Demand Forecasting & Inventory Optimization Use Case */}
            <UseCases />
            
            {/* CTA Section */}
            <section className="py-5" style={{ 
                background: 'var(--ai-gradient-1)', 
                position: 'relative', 
                overflow: 'hidden',
                padding: isMobile ? '40px 0' : '50px 0'
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'radial-gradient(circle at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)',
                }}></div>
                <Container className="position-relative py-4 py-md-5" style={{ zIndex: 2 }}>
                    <Row className="align-items-center">
                        <Col lg={8} md={12} className="text-white text-center text-lg-start mb-4 mb-lg-0" data-aos="fade-right">
                            <h2 className="mb-3" style={{ 
                                fontSize: isMobile ? '1.6rem' : isTablet ? '2rem' : '2.25rem',
                                lineHeight: isMobile ? '1.3' : '1.4'
                            }}>Ready to stop guessing and start growing?</h2>
                        </Col>
                        <Col lg={4} md={12} className="text-center" data-aos="fade-left">
                            <motion.button
                                className="btn btn-light btn-lg"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.97 }}
                                onClick={handleOpenModal}
                                style={{ 
                                    padding: isMobile ? '12px 20px' : '12px 24px',
                                    fontSize: isMobile ? '0.95rem' : '1rem',
                                    marginTop: isMobile ? '10px' : '0',
                                    touchAction: 'manipulation',
                                    minWidth: isMobile ? '180px' : '200px'
                                }}
                            >
                                Unlock Smarter Decisions
                            </motion.button>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* Footer */}
            <Footer />
            
            {/* Modal - responsive sizing */}
            <Modal show={showModal} onHide={handleCloseModal} centered size={isMobile ? "sm" : "lg"}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="w-100 text-center" style={{ fontSize: isMobile ? '1.4rem' : '1.75rem' }}>Get Your AI Business Advisor</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: isMobile ? '15px' : '20px' }}>
                    <ContactUsForm closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Home;
