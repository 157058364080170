import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { motion } from 'framer-motion';
import '../../custom.css';

function NavBar() {
    const [scrolled, setScrolled] = useState(false);
    
    // Add scroll event listener to change navbar appearance on scroll
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar 
            expand="lg" 
            fixed="top"
            className={`py-3 ${scrolled ? 'navbar-scrolled' : ''}`}
            style={{
                background: scrolled ? 'var(--ai-glass-dark)' : 'transparent',
                backdropFilter: scrolled ? 'blur(10px)' : 'none',
                boxShadow: scrolled ? '0 5px 30px rgba(0, 0, 0, 0.1)' : 'none',
                transition: 'all 0.3s ease'
            }}
        >
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand className="d-flex align-items-center">
                        <div className="logo-glow-container">
                            <motion.div
                                className="logo-glow"
                                animate={{ 
                                    boxShadow: [
                                        '0 0 10px rgba(255, 255, 255, 0.5)', 
                                        '0 0 20px rgba(255, 255, 255, 0.7)', 
                                        '0 0 10px rgba(255, 255, 255, 0.5)'
                                    ],
                                    scale: [1, 1.05, 1]
                                }}
                                transition={{ 
                                    duration: 3, 
                                    repeat: Infinity, 
                                    repeatType: 'loop'
                                }}
                            />
                            
                            {/* Orbiting elements representing the 3-body problem */}
                            <motion.div 
                                className="orbital-body orbital-body-1"
                                animate={{
                                    rotate: [15, 375] // Start at 15 degrees, rotate to 15+360 degrees
                                }}
                                transition={{
                                    duration: 15,
                                    repeat: Infinity,
                                    ease: "linear"
                                }}
                                style={{
                                    transform: "translate(-50%, -50%)",
                                    left: "0%",
                                    top: "-20%"
                                }}
                            />
                            
                            <motion.div 
                                className="orbital-body orbital-body-2"
                                animate={{
                                    rotate: [-20, -380] // Start at -20 degrees, rotate to -20-360 degrees
                                }}
                                transition={{
                                    duration: 20,
                                    repeat: Infinity,
                                    ease: "linear"
                                }}
                                style={{
                                    transform: "translate(-50%, -50%)",
                                    left: "-5%",
                                    top: "-20%"
                                }}
                            />
                            
                            <motion.div 
                                className="orbital-body orbital-body-3"
                                animate={{
                                    rotate: [30, 390] // Start at 30 degrees, rotate to 30+360 degrees
                                }}
                                transition={{
                                    duration: 25,
                                    repeat: Infinity,
                                    ease: "linear"
                                }}
                                style={{
                                    transform: "translate(-50%, -50%)",
                                    left: "-10%",
                                    top: "-20%"
                                }}
                            />

                            {/* 3D Orbital dots that pass in front and behind the logo */}
                            <motion.div 
                                className="orbital-dot dot-1"
                                animate={{
                                    rotate: 360,
                                    zIndex: [5, 5, 0, 0, 5],
                                }}
                                transition={{
                                    rotate: {
                                        duration: 8,
                                        repeat: Infinity,
                                        ease: "linear"
                                    },
                                    zIndex: {
                                        duration: 8,
                                        repeat: Infinity,
                                        ease: "linear",
                                        times: [0, 0.25, 0.3, 0.75, 0.8]
                                    }
                                }}
                                style={{
                                    width: "4px",
                                    height: "4px",
                                    borderRadius: "50%",
                                    backgroundColor: "var(--ai-teal)",
                                    position: "absolute",
                                    top: "0",
                                    left: "50%",
                                    marginLeft: "40px",
                                    marginTop: "13px",
                                    transformOrigin: "-40px 17px",
                                }}
                            />
                            
                            <motion.div 
                                className="orbital-dot dot-2"
                                animate={{
                                    rotate: -360,
                                    zIndex: [5, 5, 0, 0, 5],
                                }}
                                transition={{
                                    rotate: {
                                        duration: 12,
                                        repeat: Infinity,
                                        ease: "linear"
                                    },
                                    zIndex: {
                                        duration: 12,
                                        repeat: Infinity,
                                        ease: "linear", 
                                        times: [0, 0.25, 0.3, 0.75, 0.8]
                                    }
                                }}
                                style={{
                                    width: "4px",
                                    height: "4px",
                                    borderRadius: "50%",
                                    backgroundColor: "var(--ai-purple)",
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    marginTop: "13px",
                                    transformOrigin: "25px -13px",
                                }}
                            />
                            
                            <motion.div 
                                className="orbital-dot dot-3"
                                animate={{
                                    rotate: 360,
                                    zIndex: [5, 5, 0, 0, 5],
                                }}
                                transition={{
                                    rotate: {
                                        duration: 17,
                                        repeat: Infinity,
                                        ease: "linear"
                                    },
                                    zIndex: {
                                        duration: 17,
                                        repeat: Infinity,
                                        ease: "linear",
                                        times: [0, 0.25, 0.3, 0.75, 0.8]
                                    }
                                }}
                                style={{
                                    width: "4px",
                                    height: "4px",
                                    borderRadius: "50%",
                                    backgroundColor: "var(--ai-blue)",
                                    position: "absolute",
                                    top: "25px",
                                    right: "10px",
                                    transformOrigin: "-25px 13px",
                                }}
                            />
                            
                            <motion.img
                                src="/logo-rama.png"
                                width="50"
                                height="50"
                                className="d-inline-block align-top logo-image"
                                alt="ARAMA Logo"
                                style={{ marginLeft: "30px", zIndex: 3 }} // Set a z-index for the logo
                            />
                        </div>
                        <motion.span 
                            style={{ 
                                fontSize: '1.8rem', 
                                fontWeight: 'bold', 
                                marginLeft: '30px', // Increase margin to move ARAMA text right
                                fontFamily: 'Ubuntu, sans-serif',
                                background: 'var(--ai-gradient-2)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                            whileHover={{ scale: 1.05 }}
                        >
                            ARAMA
                        </motion.span>
                </Navbar.Brand>
                </LinkContainer>
                
                <Navbar.Toggle 
                    aria-controls="basic-navbar-nav" 
                    style={{ 
                        border: 'none', 
                        color: 'var(--ai-light)',
                        background: 'transparent' 
                    }}
                />
                
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto" style={{ gap: '1rem' }}>
                        <LinkContainer to="/">
                            <Nav.Link className="nav-item">Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about_us">
                            <Nav.Link className="nav-item">About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Link className="nav-item">Contact</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
