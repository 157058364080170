import React, { useEffect } from 'react';
import ContactUsForm from './ContactUsForm';
import Footer from './Footer';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import AOS from 'aos';

function Contact() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);

        // Initialize AOS animation library
        AOS.init({
            duration: 1000,
            once: false,
            mirror: true,
        });
    }, []);

    return (
        <div className="modern-ai-theme">
            <Container className="hero-section py-5">
                <Row className="align-items-center">
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="col-12 text-center mb-5"
                    >
                        <h1 className="section-heading">Get in <span className="main-heading">Touch</span></h1>
                        <div className="title-underline mx-auto"></div>
                        <p className="modern-paragraph">
                            We'd love to hear from you! Fill out the form below and we'll get back to you as soon as possible.
                        </p>
                    </motion.div>
                </Row>

                <Row className="align-items-center justify-content-center">
                        <div className="contact-form-wrapper">
                            <h3 className="section-heading">Contact Us</h3>
                            <ContactUsForm />
                        </div>
                </Row>
            </Container>

            <Container fluid className="my-2 py-2 contact-page-bottom">
                <Row className="align-items-center justify-content-center text-center">
                    <Col xs={12} sm={8} className="bordered-title" data-aos="fade-up">
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            className="section-title-container"
                        >
                            <h2>Ready to <span style={{ color: 'var(--ai-teal)' }}>Transform</span> Your Business?</h2>
                            <div className="title-underline"></div>
                        </motion.div>
                        <p className="modern-paragraph">
                            Our team is ready to help you harness the power of AI to make smarter business decisions. 
                            Get in touch and start your journey towards data-driven success today.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    );
}

export default Contact;
