import React, { useState } from "react";
import { motion } from "framer-motion";

const ContactUsForm = () => {
    const [language, setLanguage] = useState("EN");

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const getFormUrl = () => {
        if (language === "EN") {
            return "https://tally.so/embed/3l00e6?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1";
        } else if (language === "DE") {
            return "https://tally.so/embed/mKPgZM?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"; // Replace with your German form URL
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="modern-contact-form"
        >
            <div className="language-selector">
                <label htmlFor="language-select" className="language-label">
                    Select Language:
                </label>
                <div className="select-wrapper">
                    <select
                        id="language-select"
                        value={language}
                        onChange={handleLanguageChange}
                        className="modern-select"
                    >
                        <option value="EN">English</option>
                        <option value="DE">Deutsch</option>
                    </select>
                    <div className="select-arrow">
                        <i className="bi bi-chevron-down"></i>
                    </div>
                </div>
            </div>
            <div className="form-container">
                <iframe
                    src={getFormUrl()}
                    width="100%"
                    height="440px"
                    title="contact_us"
                    className="modern-iframe"
                >
                    Loading...
                </iframe>
            </div>
        </motion.div>
    );
};

export default ContactUsForm;
