// Footer.js
import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer = () => {
    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    return (
        <footer className="modern-footer">
            <div className="footer-particles"></div>
            <Container>
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                >
                    <Row>
                        <Col xs={12} md={4} className="mb-1 mb-md-0">
                            <motion.div variants={itemVariants} className="footer-brand">
                                <div className="footer-logo-container">
                                    <img 
                                        src="/logo-rama.png" 
                                        alt="Logo" 
                                        className="footer-logo" 
                                        style={{
                                            maxWidth: '60px',
                                            height: 'auto',
                                            filter: 'drop-shadow(0 0 5px rgba(250, 250, 250, 0.5))'
                                        }}
                                    />
                                </div>
                                <div className="footer-brand-text">
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="rama-tooltip" className="modern-tooltip">
                                                AI-Driven Real-time Analytics and Management Advisor
                                            </Tooltip>
                                        }
                                    >
                                        <h3 className="footer-title">ARAMA</h3>
                                    </OverlayTrigger>
                                    <p className="footer-tagline">
                                        Your 24/7 AI Business Advisor
                                    </p>
                                </div>
                            </motion.div>
                        </Col>

                        <Col xs={12} md={4} className="mb-1 mb-md-0">
                            <motion.h5 variants={itemVariants} className="footer-heading">Quick Links</motion.h5>
                            <motion.ul variants={itemVariants} className="footer-links">
                                <li><Link to="/" className="footer-link">Home</Link></li>
                                <li><Link to="/about_us" className="footer-link">About Us</Link></li>
                                <li><Link to="/faq" className="footer-link">FAQ</Link></li>
                                <li><Link to="/contact" className="footer-link">Contact</Link></li>
                            </motion.ul>
                        </Col>

                        <Col xs={12} md={4}>
                            <motion.h5 variants={itemVariants} className="footer-heading">Contact Us</motion.h5>
                            <motion.div variants={itemVariants} className="footer-contact">
                                <div className="contact-item">
                                    <i className="bi bi-envelope"></i>
                                    <span>
                                        <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>
                                            Contact Us
                                        </Link>
                                    </span>
                                </div>
                                <div className="contact-item">
                                    <i className="bi bi-geo-alt"></i>
                                    <span>Berlin, Germany</span>
                                </div>
                                <div className="contact-item">
                                    <i className="bi bi-linkedin"></i>
                                    <span>
                                        <a href="https://www.linkedin.com/company/arama-technology/" 
                                           target="_blank" 
                                           rel="noopener noreferrer"
                                           style={{ color: 'inherit', textDecoration: 'none' }}>
                                            LinkedIn
                                        </a>
                                    </span>
                                </div>
                            </motion.div>
                        </Col>
                    </Row>

                    <motion.div 
                        variants={itemVariants}
                        className="footer-bottom"
                    >
                        <div className="copyright">
                            © 2024 ARAMA. All rights reserved.
                        </div>
                        <div className="footer-legal">
                            <Link to="/tnc" className="legal-link">Terms</Link>
                            <Link to="/privacy" className="legal-link">Privacy</Link>
                        </div>
                    </motion.div>
                </motion.div>
            </Container>
        </footer>
    );
};

export default Footer;
