// src/components/public/data/FAQ.js
// Component to show list of frequently asked questions

const faqs = [
    {
        id: 1,
        question: "How do I get started?",
        answer: "The easiest way is to simply use the contact us page and reach out to us so that we can schedule a call with you."
    },
    {
        id: 2,
        question: "What do you do with my data?",
        answer: "Your data is used only for you! We don't sell or share your data. The only 'usage' of your data will be to create recommendations for you, providing tailored insights."
    },
    {
        id: 3,
        question: "Where is the data stored?",
        answer: "The data from your system stays with you. ARAMA simply connects to your database (of course, with your permission) to gather insights. Furthermore, we analyze the data internally through proprietary models and unlike some solutions in the market, we do not send your raw data to any 3rd party providers including LLM model providers such as OpenAI."
    },
    {
        id: 4,
        question: "How secure is my data?",
        answer: "Your data security is our top priority. We use industry-standard encryption and host our servers in highly secure facilities to ensure your data is safe at all times."
    },
    {
        id: 5,
        question: "What makes ARAMA different from other AI solutions?",
        answer: "ARAMA is designed with simplicity and customer support at its core. Unlike many other solutions that are complex and costly, ARAMA is affordable, straightforward, and backed by a dedicated support team ready to help whenever you need. We work with you to understand your business and provide you with the best possible solutions."
    },
    {
        id: 6,
        question: "Do I need technical expertise to use ARAMA?",
        answer: "Not at all! ARAMA has been designed to be intuitive and easy to use for anyone, regardless of technical expertise. Our user-friendly interface allows you to start leveraging AI-driven insights without any specialized knowledge."
    },
    {
        id: 7,
        question: "How much does it cost to use ARAMA?",
        answer: "The cost of ARAMA depends on the size of your business and your specific needs. We have flexible pricing plans to fit businesses of all sizes. Please contact us for more details."
    },
    {
        id: 8,
        question: "In which industries can ARAMA be used?",
        answer: "ARAMA is designed to be flexible and applicable across a variety of industries. If your business generates data, ARAMA can be used. We cover this in details in the initial consultation."
    },
    {
        id: 9,
        question: "Can ARAMA integrate with my existing tools and software?",
        answer: "ARAMA is built to integrate with a variety of popular business tools and systems. Reach out to us with your current setup, and we’ll work with you to integrate ARAMA seamlessly into your business processes."
    },
    {
        id: 10,
        question: "How can ARAMA help my business grow?",
        answer: "ARAMA uses AI and predictive analytics to transform your business data into actionable insights. Whether it’s optimizing inventory, predicting sales trends, or making better resource allocation decisions, ARAMA helps you make data-driven decisions that lead to measurable growth."
    },
    {
        id: 11,
        question: "What type of businesses is ARAMA best suited for?",
        answer: "ARAMA is ideal for small and medium-sized businesses looking to leverage data-driven insights without the need for complex or costly enterprise software."
    }
];

export default faqs;
