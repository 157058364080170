import React from 'react';
import { motion } from 'framer-motion';

function AiVisualization() {
    
    return (
        <motion.div
            className="position-relative"
            style={{ height: '500px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
        >
            {/* Advanced AI & 3-Body Problem Visualization */}
            <div className="ai-visualization-container">
                {/* Background Neural Network */}
                <svg width="100%" height="100%" className="neural-network-svg" viewBox="0 0 500 500">
                    <defs>
                        <linearGradient id="synapseGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stopColor="var(--ai-teal)" />
                            <stop offset="100%" stopColor="var(--ai-purple)" />
                        </linearGradient>
                        <linearGradient id="synapseGradient2" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stopColor="var(--ai-purple)" />
                            <stop offset="100%" stopColor="var(--ai-blue)" />
                        </linearGradient>
                        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feComposite in="SourceGraphic" in2="blur" operator="over" />
                        </filter>
                    </defs>
                    
                    {/* Neural Network Layer Groups */}
                    <g className="input-layer network-layer">
                        {[...Array(5)].map((_, i) => (
                            <motion.circle 
                                key={`input-${i}`} 
                                cx={100} 
                                cy={100 + i * 60} 
                                r={8}
                                fill="var(--ai-teal)"
                                filter="url(#glow)"
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ 
                                    opacity: 1, 
                                    scale: [0, 1.2, 1],
                                    fill: ["var(--ai-teal)", "var(--ai-purple)", "var(--ai-teal)"]
                                }}
                                transition={{ 
                                    duration: 2,
                                    delay: i * 0.1,
                                    fill: {
                                        duration: 3,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                            />
                        ))}
                    </g>
                    
                    <g className="hidden-layer network-layer">
                        {[...Array(7)].map((_, i) => (
                            <motion.circle 
                                key={`hidden-${i}`} 
                                cx={250} 
                                cy={70 + i * 50} 
                                r={8}
                                fill="var(--ai-purple)"
                                filter="url(#glow)"
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ 
                                    opacity: 1, 
                                    scale: [0, 1.2, 1],
                                    fill: ["var(--ai-purple)", "var(--ai-blue)", "var(--ai-purple)"]
                                }}
                                transition={{ 
                                    duration: 2,
                                    delay: 0.3 + i * 0.1,
                                    fill: {
                                        duration: 4,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                            />
                        ))}
                    </g>
                    
                    <g className="output-layer network-layer">
                        {[...Array(4)].map((_, i) => (
                            <motion.circle 
                                key={`output-${i}`} 
                                cx={400} 
                                cy={120 + i * 70} 
                                r={8}
                                fill="var(--ai-blue)"
                                filter="url(#glow)"
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ 
                                    opacity: 1, 
                                    scale: [0, 1.2, 1],
                                    fill: ["var(--ai-blue)", "var(--ai-teal)", "var(--ai-blue)"]
                                }}
                                transition={{ 
                                    duration: 2,
                                    delay: 0.6 + i * 0.1,
                                    fill: {
                                        duration: 5,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                            />
                        ))}
                    </g>
                    
                    {/* Neural Network Connections - Input to Hidden */}
                    {[...Array(5)].map((_, i) => (
                        [...Array(7)].map((_, j) => (
                            <motion.path
                                key={`conn-i${i}-h${j}`}
                                d={`M ${100} ${100 + i * 60} C ${175} ${85 + i * 60}, ${175} ${70 + j * 50}, ${250} ${70 + j * 50}`}
                                stroke="url(#synapseGradient)"
                                strokeWidth="1"
                                strokeOpacity="0.5"
                                fill="none"
                                initial={{ pathLength: 0, opacity: 0 }}
                                animate={{ pathLength: 1, opacity: 0.4 }}
                                transition={{ 
                                    duration: 1,
                                    delay: 0.8 + (i * 0.05) + (j * 0.02)
                                }}
                            />
                        ))
                    ))}
                    
                    {/* Neural Network Connections - Hidden to Output */}
                    {[...Array(7)].map((_, i) => (
                        [...Array(4)].map((_, j) => (
                            <motion.path
                                key={`conn-h${i}-o${j}`}
                                d={`M ${250} ${70 + i * 50} C ${325} ${70 + i * 50}, ${325} ${120 + j * 70}, ${400} ${120 + j * 70}`}
                                stroke="url(#synapseGradient2)"
                                strokeWidth="1"
                                strokeOpacity="0.5"
                                fill="none"
                                initial={{ pathLength: 0, opacity: 0 }}
                                animate={{ pathLength: 1, opacity: 0.4 }}
                                transition={{ 
                                    duration: 1,
                                    delay: 1.2 + (i * 0.03) + (j * 0.04)
                                }}
                            />
                        ))
                    ))}
                    
                    {/* Data Flow Pulse Animation */}
                    {[...Array(10)].map((_, i) => (
                        <motion.circle
                            key={`pulse-${i}`}
                            r={3}
                            fill="var(--ai-teal)"
                            filter="url(#glow)"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: [0, 0.8, 0],
                                cx: [100, 250, 400],
                                cy: [
                                    100 + (i % 5) * 60, 
                                    70 + (i % 7) * 50, 
                                    120 + (i % 4) * 70
                                ]
                            }}
                            transition={{
                                duration: 2,
                                delay: i * 1.5,
                                repeat: Infinity,
                                repeatDelay: 3 + (i % 3)
                            }}
                        />
                    ))}
                </svg>
                
                {/* Animated data signals traveling through the network */}
                {[...Array(15)].map((_, i) => (
                    <motion.div
                        key={`data-signal-${i}`}
                        className="data-signal"
                        initial={{ 
                            left: "100px", 
                            top: `${100 + (i % 5) * 60}px`,
                            opacity: 0,
                            scale: 0
                        }}
                        animate={[
                            // Move from input to random hidden node
                            {
                                left: "100px",
                                top: `${100 + (i % 5) * 60}px`,
                                opacity: 1,
                                scale: 1,
                                transition: { 
                                    duration: 0.01, 
                                    delay: 2 + i * 0.5,
                                    ease: "easeOut" 
                                }
                            },
                            // Move to hidden layer
                            {
                                left: "250px",
                                top: `${70 + (i % 7) * 50}px`,
                                opacity: 1,
                                backgroundColor: "var(--ai-purple)",
                                transition: { 
                                    duration: 0.5, 
                                    delay: 0.01,
                                    ease: "easeInOut" 
                                }
                            },
                            // Move to output layer
                            {
                                left: "400px",
                                top: `${120 + (i % 4) * 70}px`,
                                opacity: 1,
                                backgroundColor: "var(--ai-blue)",
                                scale: 1.5,
                                transition: { 
                                    duration: 0.5, 
                                    delay: 0.01,
                                    ease: "easeInOut" 
                                }
                            },
                            // Fade out
                            {
                                opacity: 0,
                                scale: 0,
                                transition: { 
                                    duration: 0.3, 
                                    delay: 0.01,
                                    ease: "easeOut" 
                                }
                            }
                        ]}
                        transition={{
                            repeat: Infinity,
                            repeatDelay: 3 + Math.random() * 5
                        }}
                    />
                ))}
                
                {/* 3-Body Problem Orbital System */}
                <div className="three-body-system">
                    <motion.div 
                        className="orbital-center"
                        animate={{
                            boxShadow: [
                                "0 0 20px 5px rgba(0, 201, 224, 0.7)",
                                "0 0 30px 10px rgba(0, 201, 224, 0.9)",
                                "0 0 20px 5px rgba(0, 201, 224, 0.7)"
                            ],
                            scale: [1, 1.1, 1]
                        }}
                        transition={{
                            duration: 3,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                    />
                    
                    {/* 3-Body Orbits */}
                    {[1, 2, 3].map((body, index) => (
                        <motion.div
                            key={`orbit-path-${index}`}
                            className={`orbit-path orbit-${index + 1}`}
                            animate={{ 
                                rotate: 360 * (index % 2 === 0 ? 1 : -1),
                                scale: [1, 1.05, 1]
                            }}
                            transition={{
                                rotate: {
                                    duration: 20 + index * 5,
                                    repeat: Infinity,
                                    ease: "linear"
                                },
                                scale: {
                                    duration: 10 + index * 2,
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut"
                                }
                            }}
                        >
                            <motion.div 
                                className={`orbital-body orbital-body-${index + 1}`}
                                animate={{ 
                                    boxShadow: [
                                        `0 0 5px 2px ${index === 0 ? 'rgba(0, 201, 224, 0.5)' : index === 1 ? 'rgba(110, 17, 244, 0.5)' : 'rgba(30, 56, 247, 0.5)'}`,
                                        `0 0 10px 5px ${index === 0 ? 'rgba(0, 201, 224, 0.7)' : index === 1 ? 'rgba(110, 17, 244, 0.7)' : 'rgba(30, 56, 247, 0.7)'}`,
                                        `0 0 5px 2px ${index === 0 ? 'rgba(0, 201, 224, 0.5)' : index === 1 ? 'rgba(110, 17, 244, 0.5)' : 'rgba(30, 56, 247, 0.5)'}`
                                    ],
                                    scale: [1, 1.2, 1]
                                }}
                                transition={{
                                    boxShadow: {
                                        duration: 2 + index,
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        ease: "easeInOut"
                                    },
                                    scale: {
                                        duration: 1.5 + index * 0.5,
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        ease: "easeInOut"
                                    }
                                }}
                            />
                        </motion.div>
                    ))}
                    
                    {/* Main Neural Core */}
                    <motion.div 
                        className="neural-core"
                        animate={{ 
                            rotate: 360,
                            scale: [1, 1.05, 1]
                        }}
                        transition={{
                            rotate: {
                                duration: 30,
                                repeat: Infinity,
                                ease: "linear"
                            },
                            scale: {
                                duration: 3,
                                repeat: Infinity,
                                repeatType: "reverse",
                                ease: "easeInOut"
                            }
                        }}
                    >
                        <svg width="100%" height="100%" viewBox="0 0 200 200">
                            <defs>
                                <linearGradient id="coreGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                    <stop offset="0%" stopColor="var(--ai-teal)" />
                                    <stop offset="50%" stopColor="var(--ai-purple)" />
                                    <stop offset="100%" stopColor="var(--ai-blue)" />
                                </linearGradient>
                                <filter id="coreGlow" x="-50%" y="-50%" width="200%" height="200%">
                                    <feGaussianBlur stdDeviation="5" result="blur" />
                                    <feComposite in="SourceGraphic" in2="blur" operator="over" />
                                </filter>
                            </defs>
                            
                            {/* Neural Core Pattern */}
                            <motion.path 
                                d="M100,30 C140,30 160,60 160,100 C160,140 140,170 100,170 C60,170 40,140 40,100 C40,60 60,30 100,30 Z" 
                                fill="none" 
                                stroke="url(#coreGradient)"
                                strokeWidth="2"
                                filter="url(#coreGlow)"
                                initial={{ pathLength: 0 }}
                                animate={{ 
                                    pathLength: 1,
                                    rotate: 360
                                }}
                                transition={{ 
                                    pathLength: { duration: 2 },
                                    rotate: { 
                                        duration: 30, 
                                        repeat: Infinity,
                                        ease: "linear" 
                                    }
                                }}
                            />
                            
                            <motion.path 
                                d="M100,50 C130,50 140,70 140,100 C140,130 130,150 100,150 C70,150 60,130 60,100 C60,70 70,50 100,50 Z" 
                                fill="none" 
                                stroke="url(#coreGradient)"
                                strokeWidth="2"
                                strokeDasharray="5,5"
                                filter="url(#coreGlow)"
                                initial={{ pathLength: 0 }}
                                animate={{ 
                                    pathLength: 1,
                                    rotate: -360
                                }}
                                transition={{ 
                                    pathLength: { duration: 2, delay: 0.5 },
                                    rotate: { 
                                        duration: 25, 
                                        repeat: Infinity,
                                        ease: "linear" 
                                    }
                                }}
                            />
                            
                            <motion.path 
                                d="M100,70 C120,70 120,80 120,100 C120,120 120,130 100,130 C80,130 80,120 80,100 C80,80 80,70 100,70 Z" 
                                fill="none" 
                                stroke="url(#coreGradient)"
                                strokeWidth="2"
                                filter="url(#coreGlow)"
                                initial={{ pathLength: 0 }}
                                animate={{ 
                                    pathLength: 1,
                                    rotate: 180
                                }}
                                transition={{ 
                                    pathLength: { duration: 2, delay: 1 },
                                    rotate: { 
                                        duration: 20, 
                                        repeat: Infinity,
                                        ease: "linear" 
                                    }
                                }}
                            />
                            
                            <motion.circle 
                                cx="100" 
                                cy="100" 
                                r="15"
                                fill="url(#coreGradient)"
                                filter="url(#coreGlow)"
                                initial={{ scale: 0 }}
                                animate={{ 
                                    scale: 1,
                                    r: [15, 18, 15]
                                }}
                                transition={{ 
                                    scale: { duration: 1, delay: 1.5 },
                                    r: {
                                        duration: 3,
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        ease: "easeInOut"
                                    }
                                }}
                            />
                        </svg>
                    </motion.div>
                    
                    {/* Machine Learning Data Particles */}
                    {[...Array(30)].map((_, i) => (
                        <motion.div
                            key={`ml-particle-${i}`}
                            className="ml-particle"
                            style={{
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                width: `${2 + Math.random() * 4}px`,
                                height: `${2 + Math.random() * 4}px`,
                                backgroundColor: i % 3 === 0 
                                    ? 'var(--ai-teal)' 
                                    : i % 3 === 1 
                                        ? 'var(--ai-purple)' 
                                        : 'var(--ai-blue)'
                            }}
                            animate={{
                                x: [0, (Math.random() * 80) - 40, 0],
                                y: [0, (Math.random() * 80) - 40, 0],
                                opacity: [0.3, 0.8, 0.3],
                                scale: [1, 1.5, 1]
                            }}
                            transition={{
                                duration: 3 + Math.random() * 7,
                                repeat: Infinity,
                                ease: "easeInOut",
                                delay: Math.random() * 2
                            }}
                        />
                    ))}
                </div>
            </div>
        </motion.div>
    );
}

export default AiVisualization; 