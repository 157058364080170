import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';

const Features = ({
  feature1Title = 'Predictive Analytics',
  feature1Description = 'Leverage your data alongside select external sources, including satellite insights, to anticipate trends and outcomes such as weather impact, market shifts, seasonal patterns, and evolving consumer behaviors.',
  feature1Icon = "bi bi-graph-up",
  feature2Title = 'StrataMemory™',
  feature2Description = 'Our proprietary system learns from you, for you, over time, identifying patterns specific to your business and refining insights to enhance future decision-making.',
  feature2Icon = "bi bi-cpu",
  feature3Title = 'AI-Powered Insights',
  feature3Description = 'Interact with AI for real-time recommendations and proactive suggestions to optimize your strategies.',
  feature3Icon = "bi bi-lightning-charge",
}) => {
  const [activeFeature, setActiveFeature] = useState(0);
  const controls = useAnimation();

  const features = [
    {
      id: 0,
      title: feature1Title,
      description: feature1Description,
      icon: feature1Icon,
      color: "#00C9FF",
      gradient: "linear-gradient(135deg, #00C9FF 0%, #92FE9D 100%)",
      particles: 40
    },
    {
      id: 1,
      title: feature2Title,
      description: feature2Description,
      icon: feature2Icon,
      color: "#9733EE",
      gradient: "linear-gradient(135deg, #9733EE 0%, #DA22FF 100%)",
      particles: 30
    },
    {
      id: 2,
      title: feature3Title,
      description: feature3Description,
      icon: feature3Icon,
      color: "#FFD166",
      gradient: "linear-gradient(135deg, #FFD166 0%, #FF6B6B 100%)",
      particles: 35
    },
  ];

  // Auto-rotate features
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % features.length);
    }, 10000); // Interval 10 seconds
    
    return () => clearInterval(interval);
  }, [features.length]);

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [activeFeature, controls]);

  // Generate particles
  const generateParticles = (count, color) => {
    return Array.from({ length: count }).map((_, index) => ({
      id: index,
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 4 + 2,
      duration: Math.random() * 20 + 10,
      delay: Math.random() * 5,
      color
    }));
  };

  // Card animation variants
  const cardVariants = {
    inactive: { 
      scale: 0.95, 
      opacity: 0.7,
      y: 20,
      transition: { duration: 0.5 }
    },
    active: { 
      scale: 1, 
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, type: "spring", stiffness: 300, damping: 24 }
    }
  };

  // Particle animation component
  const ParticleField = ({ color, count }) => {
    const particles = generateParticles(count, color);
    
    return (
      <div className="particle-container">
        {particles.map(particle => (
          <motion.div
            key={particle.id}
            className="particle"
            initial={{ 
              x: `${particle.x}%`, 
              y: `${particle.y}%`, 
              opacity: 0 
            }}
            animate={{ 
              x: [`${particle.x}%`, `${(particle.x + 20) % 100}%`, `${(particle.x - 10) % 100}%`],
              y: [`${particle.y}%`, `${(particle.y - 20) % 100}%`, `${(particle.y + 15) % 100}%`],
              opacity: [0, 0.8, 0]
            }}
            transition={{ 
              duration: particle.duration,
              delay: particle.delay,
              repeat: Infinity,
              repeatType: "reverse"
            }}
            style={{
              width: `${particle.size}px`,
              height: `${particle.size}px`,
              backgroundColor: particle.color,
              borderRadius: '50%',
              position: 'absolute'
            }}
          />
        ))}
      </div>
    );
  };
    
  return (
    <section className="features-section py-5" style={{ background: 'var(--ai-dark)', position: 'relative', overflow: 'hidden' }}>
      {/* Background grid and glow effect */}
      <div className="features-bg-grid"></div>
      <div className="features-glow" style={{ 
        background: features[activeFeature].gradient,
        opacity: 0.05,
        filter: 'blur(150px)',
        position: 'absolute',
        width: '60%',
        height: '60%',
        top: '20%',
        left: '20%',
        borderRadius: '50%',
        zIndex: 0
      }}></div>
      
      <Container className="position-relative" style={{ zIndex: 2 }}>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-5"
        >
          <h2>Powerful <span style={{ color: 'var(--ai-teal)' }}>Benefits</span></h2>
          <div className="title-underline mx-auto"></div>
          <p className="modern-paragraph">Discover how our innovative AI solutions can transform your business operations</p>
        </motion.div>

        <Row className="align-items-center">
          {/* Left column - Animation */}
          <Col lg={6} className="position-relative">
            <div className="animation-container" style={{ height: '500px', position: 'relative' }}>
              <AnimatePresence mode="wait">
                {features.map((feature, index) => (
                  activeFeature === index && (
                    <motion.div
                      key={`animation-${feature.id}`}
                      className="animation-wrapper"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.6 }}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'rgba(20, 21, 30, 0.4)',
                        borderRadius: '24px',
                        overflow: 'hidden'
                      }}
                    >
                      {/* Particle animation in background */}
                      <ParticleField color={feature.color} count={feature.particles} />
                      
                      {index === 0 && (
                        <div className="predictive-visual" style={{ position: 'relative', height: '100%' }}>
                          {/* Graph visualization */}
                          <svg width="100%" height="100%" viewBox="0 0 300 300" style={{ position: 'absolute' }}>
                            <defs>
                              <linearGradient id="chartGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" stopColor={feature.color} stopOpacity="0.8" />
                                <stop offset="100%" stopColor={feature.color} stopOpacity="0.1" />
                              </linearGradient>
                            </defs>
                          
                            {/* Grid lines */}
                            {[...Array(6)].map((_, i) => (
                              <motion.line 
                                key={`h-${i}`}
                                x1="40" 
                                y1={50 + i * 40} 
                                x2="260" 
                                y2={50 + i * 40} 
                                stroke="rgba(255,255,255,0.1)" 
                                strokeWidth="1"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={{ duration: 1, delay: 0.1 * i }}
                              />
                            ))}
                            
                            {[...Array(6)].map((_, i) => (
                              <motion.line 
                                key={`v-${i}`}
                                x1={40 + i * 44} 
                                y1="50" 
                                x2={40 + i * 44} 
                                y2="250" 
                                stroke="rgba(255,255,255,0.1)" 
                                strokeWidth="1"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={{ duration: 1, delay: 0.1 * i }}
                              />
                            ))}
                          
                            {/* Line chart */}
                            <motion.path
                              d="M40,200 C80,180 100,220 140,160 S200,100 220,140 S240,110 260,90"
                              fill="none"
                              stroke={feature.color}
                              strokeWidth="3"
                              initial={{ pathLength: 0 }}
                              animate={{ pathLength: 1 }}
                              transition={{ duration: 2 }}
                            />
                          
                            {/* Area under chart */}
                            <motion.path
                              d="M40,200 C80,180 100,220 140,160 S200,100 220,140 S240,110 260,90 V250 H40 Z"
                              fill="url(#chartGradient)"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1, delay: 1 }}
                            />
                          
                            {/* Forecast dotted line */}
                            <motion.path
                              d="M260,90 C280,70 290,60 300,50"
                              fill="none"
                              stroke={feature.color}
                              strokeWidth="3"
                              strokeDasharray="5,5"
                              initial={{ pathLength: 0 }}
                              animate={{ pathLength: 1 }}
                              transition={{ duration: 1, delay: 2 }}
                            />
                          
                            {/* Data points */}
                            {[
                              {x: 40, y: 200}, {x: 84, y: 180}, {x: 128, y: 190}, 
                              {x: 172, y: 120}, {x: 216, y: 140}, {x: 260, y: 90}
                            ].map((point, i) => (
                              <motion.circle
                                key={`point-${i}`}
                                cx={point.x}
                                cy={point.y}
                                r="6"
                                fill="#fff"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ delay: 0.5 + i * 0.2 }}
                              />
                            ))}
                          </svg>
                          
                          {/* Floating data labels */}
                          <motion.div 
                            style={{ 
                              position: 'absolute', 
                              top: '30%', 
                              right: '25%',
                              background: 'rgba(0,0,0,0.7)',
                              backdropFilter: 'blur(10px)',
                              padding: '10px 15px',
                              borderRadius: '10px',
                              border: `1px solid ${feature.color}`
                            }}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.5 }}
                          >
                            <div style={{ color: feature.color, fontWeight: 'bold' }}>+42% Growth</div>
                          </motion.div>
                          
                          <motion.div 
                            style={{ 
                              position: 'absolute', 
                              bottom: '25%', 
                              left: '30%',
                              background: 'rgba(0,0,0,0.7)',
                              backdropFilter: 'blur(10px)',
                              padding: '10px 15px',
                              borderRadius: '10px',
                              border: `1px solid ${feature.color}`
                            }}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.8 }}
                          >
                            <div style={{ color: feature.color, fontWeight: 'bold' }}>Upward Trend Identified</div>
                          </motion.div>
                        </div>
                      )}
                      
                      {index === 1 && (
                        <div className="strataMemory-visual" style={{ position: 'relative', height: '100%' }}>
                          {/* Neural network visualization */}
                          <svg width="100%" height="100%" viewBox="0 0 300 300" style={{ position: 'absolute' }}>
                            {/* Hexagon network nodes */}
                            {[
                              {x: 150, y: 60, size: 28},  // Top
                              {x: 90, y: 95, size: 25},   // Top Left
                              {x: 210, y: 95, size: 25},  // Top Right
                              {x: 60, y: 150, size: 25},  // Left
                              {x: 240, y: 150, size: 25}, // Right
                              {x: 90, y: 205, size: 25},  // Bottom Left
                              {x: 210, y: 205, size: 25}, // Bottom Right
                              {x: 150, y: 240, size: 28}, // Bottom
                              {x: 150, y: 150, size: 35}, // Center
                            ].map((node, i) => (
                              <g key={`hex-${i}`}>
                                <motion.polygon
                                  points={`
                                    ${node.x},${node.y - node.size} 
                                    ${node.x + node.size * 0.866},${node.y - node.size * 0.5} 
                                    ${node.x + node.size * 0.866},${node.y + node.size * 0.5} 
                                    ${node.x},${node.y + node.size} 
                                    ${node.x - node.size * 0.866},${node.y + node.size * 0.5} 
                                    ${node.x - node.size * 0.866},${node.y - node.size * 0.5}
                                  `}
                                  fill="rgba(0,0,0,0.3)"
                                  stroke={feature.color}
                                  strokeWidth="2"
                                  initial={{ opacity: 0, scale: 0 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{ duration: 0.5, delay: i * 0.12 }}
                                />
                                <motion.circle
                                  cx={node.x}
                                  cy={node.y}
                                  r={node.size * 0.3}
                                  fill={feature.color}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  transition={{ duration: 0.5, delay: i * 0.12 + 0.3 }}
                                />
                              </g>
                            ))}
                            
                            {/* Connection lines */}
                            {[
                              // Outer heptagon connections
                              {x1: 150, y1: 60, x2: 90, y2: 95},    // Top to Top Left
                              {x1: 150, y1: 60, x2: 210, y2: 95},   // Top to Top Right
                              {x1: 90, y1: 95, x2: 60, y2: 150},    // Top Left to Left
                              {x1: 210, y1: 95, x2: 240, y2: 150},  // Top Right to Right
                              {x1: 60, y1: 150, x2: 90, y2: 205},   // Left to Bottom Left
                              {x1: 240, y1: 150, x2: 210, y2: 205}, // Right to Bottom Right
                              {x1: 90, y1: 205, x2: 150, y2: 240},  // Bottom Left to Bottom
                              {x1: 210, y1: 205, x2: 150, y2: 240}, // Bottom Right to Bottom
                              
                              // Connections to center
                              {x1: 150, y1: 60, x2: 150, y2: 150},  // Top to Center
                              {x1: 90, y1: 95, x2: 150, y2: 150},   // Top Left to Center
                              {x1: 210, y1: 95, x2: 150, y2: 150},  // Top Right to Center
                              {x1: 60, y1: 150, x2: 150, y2: 150},  // Left to Center
                              {x1: 240, y1: 150, x2: 150, y2: 150}, // Right to Center
                              {x1: 90, y1: 205, x2: 150, y2: 150},  // Bottom Left to Center
                              {x1: 210, y1: 205, x2: 150, y2: 150}, // Bottom Right to Center
                              {x1: 150, y1: 240, x2: 150, y2: 150}, // Bottom to Center
                            ].map((line, i) => (
                              <motion.line
                                key={`line-${i}`}
                                x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2}
                                stroke={feature.color}
                                strokeOpacity="0.7"
                                strokeWidth="2"
                                initial={{ pathLength: 0, opacity: 0 }}
                                animate={{ pathLength: 1, opacity: 0.7 }}
                                transition={{ duration: 0.8, delay: 0.8 + i * 0.08 }}
                              />
                            ))}
                            
                            {/* Data pulses - multiple dots flying in from outside */}
                            {/* Top node incoming data */}
                            {[...Array(3)].map((_, i) => {
                              const startAngle = Math.PI * (0.8 + Math.random() * 0.4); // Random angle from top
                              const startDistance = 150 + Math.random() * 100;
                              const startX = 150 + Math.cos(startAngle) * startDistance;
                              const startY = 60 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-top-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 150, 150],
                                    cy: [startY, 60, 60],
                                  }}
                                  transition={{ 
                                    duration: 2,
                                    delay: i * 2.5 + Math.random() * 2,
                                    repeat: Infinity,
                                    repeatDelay: 5 + Math.random() * 5,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Top Left node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (1.2 + Math.random() * 0.3); // Random angle from left
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 90 + Math.cos(startAngle) * startDistance;
                              const startY = 95 + Math.sin(startAngle) * startDistance;

                              return (
                                <motion.circle
                                  key={`pulse-topleft-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 90, 90],
                                    cy: [startY, 95, 95],
                                  }}
                                  transition={{
                                    duration: 2,
                                    delay: i * 3 + Math.random() * 3 + 1,
                                    repeat: Infinity,
                                    repeatDelay: 6 + Math.random() * 4,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Top Right node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (0.5 + Math.random() * 0.3); // Random angle from right
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 210 + Math.cos(startAngle) * startDistance;
                              const startY = 95 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-topright-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 210, 210],
                                    cy: [startY, 95, 95],
                                  }}
                                  transition={{
                                    duration: 2,
                                    delay: i * 2.7 + Math.random() * 2 + 0.5,
                                    repeat: Infinity,
                                    repeatDelay: 5 + Math.random() * 5,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Left node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (1.3 + Math.random() * 0.4); // Random angle from left
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 60 + Math.cos(startAngle) * startDistance;
                              const startY = 150 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-left-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 60, 60],
                                    cy: [startY, 150, 150],
                                  }}
                                  transition={{ 
                                    duration: 2, 
                                    delay: i * 3.1 + Math.random() * 2 + 1.3,
                                    repeat: Infinity,
                                    repeatDelay: 6 + Math.random() * 4,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Right node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (0.3 + Math.random() * 0.4); // Random angle from right
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 240 + Math.cos(startAngle) * startDistance;
                              const startY = 150 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-right-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 240, 240],
                                    cy: [startY, 150, 150],
                                  }}
                                  transition={{
                                    duration: 2,
                                    delay: i * 2.8 + Math.random() * 2 + 1.1,
                                    repeat: Infinity,
                                    repeatDelay: 5 + Math.random() * 5,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Bottom Left node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (1.2 + Math.random() * 0.3); // Random angle from left
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 90 + Math.cos(startAngle) * startDistance;
                              const startY = 205 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-bottomleft-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 90, 90],
                                    cy: [startY, 205, 205],
                                  }}
                                  transition={{ 
                                    duration: 2,
                                    delay: i * 3.2 + Math.random() * 2 + 1.5,
                                    repeat: Infinity,
                                    repeatDelay: 6 + Math.random() * 4,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Bottom Right node incoming data */}
                            {[...Array(2)].map((_, i) => {
                              const startAngle = Math.PI * (0.5 + Math.random() * 0.3); // Random angle from right
                              const startDistance = 100 + Math.random() * 80;
                              const startX = 210 + Math.cos(startAngle) * startDistance;
                              const startY = 205 + Math.sin(startAngle) * startDistance;

                              return (
                                <motion.circle
                                  key={`pulse-bottomright-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 210, 210],
                                    cy: [startY, 205, 205],
                                  }}
                                  transition={{
                                    duration: 2,
                                    delay: i * 2.9 + Math.random() * 2 + 1.2,
                                    repeat: Infinity,
                                    repeatDelay: 5 + Math.random() * 5,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Bottom node incoming data */}
                            {[...Array(3)].map((_, i) => {
                              const startAngle = Math.PI * (0.2 + Math.random() * 0.6); // Random angle from bottom
                              const startDistance = 150 + Math.random() * 100;
                              const startX = 150 + Math.cos(startAngle) * startDistance;
                              const startY = 240 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-bottom-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 150, 150],
                                    cy: [startY, 240, 240],
                                  }}
                                  transition={{
                                    duration: 2,
                                    delay: i * 2.6 + Math.random() * 2 + 0.8,
                                    repeat: Infinity,
                                    repeatDelay: 5 + Math.random() * 5,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Center node incoming data */}
                            {[...Array(3)].map((_, i) => {
                              const startAngle = Math.PI * 2 * Math.random(); // Random angle from any direction
                              const startDistance = 120 + Math.random() * 80;
                              const startX = 150 + Math.cos(startAngle) * startDistance;
                              const startY = 150 + Math.sin(startAngle) * startDistance;
                              
                              return (
                                <motion.circle
                                  key={`pulse-center-in-${i}`}
                                  cx={startX}
                                  cy={startY}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 1, 0],
                                    cx: [startX, 150, 150],
                                    cy: [startY, 150, 150],
                                  }}
                                  transition={{ 
                                    duration: 2,
                                    delay: i * 3.5 + Math.random() * 2 + 1,
                                    repeat: Infinity,
                                    repeatDelay: 7 + Math.random() * 3,
                                    times: [0, 0.4, 0.6, 1]
                                  }}
                                />
                              );
                            })}
                            
                            {/* Data flowing through the network */}
                            {/* Adjacent node connections */}
                            {[
                              {x1: 150, y1: 60, x2: 90, y2: 95, delay: 2.1},    // Top to Top Left
                              {x1: 150, y1: 60, x2: 210, y2: 95, delay: 2.4},   // Top to Top Right
                              {x1: 90, y1: 95, x2: 60, y2: 150, delay: 2.7},    // Top Left to Left
                              {x1: 210, y1: 95, x2: 240, y2: 150, delay: 3.0},  // Top Right to Right
                              {x1: 60, y1: 150, x2: 90, y2: 205, delay: 3.3},   // Left to Bottom Left
                              {x1: 240, y1: 150, x2: 210, y2: 205, delay: 3.6}, // Right to Bottom Right
                              {x1: 90, y1: 205, x2: 150, y2: 240, delay: 3.9},  // Bottom Left to Bottom
                              {x1: 210, y1: 205, x2: 150, y2: 240, delay: 4.2}, // Bottom Right to Bottom
                            ].map((conn, i) => (
                              <motion.circle
                                key={`pulse-adjacent-${i}`}
                                cx={conn.x1}
                                cy={conn.y1}
                                r="4"
                                fill="#fff"
                                initial={{ scale: 0 }}
                                animate={{ 
                                  scale: [0, 1, 0],
                                  cx: [conn.x1, conn.x2],
                                  cy: [conn.y1, conn.y2],
                                }}
                                transition={{
                                  duration: 1,
                                  delay: conn.delay,
                                  repeat: Infinity,
                                  repeatDelay: 8
                                }}
                              />
                            ))}
                            
                            {/* Connections to center from all nodes */}
                            {[
                              {x1: 150, y1: 60, x2: 150, y2: 150, delay: 4.5},  // Top to Center
                              {x1: 90, y1: 95, x2: 150, y2: 150, delay: 4.8},   // Top Left to Center
                              {x1: 210, y1: 95, x2: 150, y2: 150, delay: 5.1},  // Top Right to Center
                              {x1: 60, y1: 150, x2: 150, y2: 150, delay: 5.4},  // Left to Center
                              {x1: 240, y1: 150, x2: 150, y2: 150, delay: 5.7}, // Right to Center
                              {x1: 90, y1: 205, x2: 150, y2: 150, delay: 6.0},  // Bottom Left to Center
                              {x1: 210, y1: 205, x2: 150, y2: 150, delay: 6.3}, // Bottom Right to Center
                              {x1: 150, y1: 240, x2: 150, y2: 150, delay: 6.6}, // Bottom to Center
                            ].map((conn, i) => (
                              <motion.circle
                                key={`pulse-to-center-${i}`}
                                cx={conn.x1}
                                cy={conn.y1}
                                r="4"
                                fill="#fff"
                                initial={{ scale: 0 }}
                                animate={{ 
                                  scale: [0, 1, 0],
                                  cx: [conn.x1, conn.x2],
                                  cy: [conn.y1, conn.y2],
                                }}
                                transition={{ 
                                  duration: 1,
                                  delay: conn.delay,
                                  repeat: Infinity,
                                  repeatDelay: 10
                                }}
                              />
                            ))}
                            
                            {/* Data leaving the network - from bottom node */}
                            {[...Array(4)].map((_, i) => {
                              const angle = Math.PI * (0.3 + Math.random() * 0.4); // Random angle downward
                              const endDistance = 100 + Math.random() * 80;
                              const endX = 150 + Math.cos(angle) * endDistance;
                              const endY = 240 + Math.sin(angle) * endDistance;

                              return (
                                <motion.circle
                                  key={`pulse-bottom-out-${i}`}
                                  cx={150}
                                  cy={240}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 0],
                                    cx: [150, endX],
                                    cy: [240, endY],
                                  }}
                                  transition={{ 
                                    duration: 2,
                                    delay: i * 3 + 7 + Math.random() * 2,
                                    repeat: Infinity,
                                    repeatDelay: 7 + Math.random() * 3
                                  }}
                                />
                              );
                            })}
                            
                            {/* Data leaving from center node */}
                            {[...Array(4)].map((_, i) => {
                              const angle = Math.PI * (1.7 + Math.random() * 0.6); // Random angle upward
                              const endDistance = 120 + Math.random() * 80;
                              const endX = 150 + Math.cos(angle) * endDistance;
                              const endY = 150 + Math.sin(angle) * endDistance;

                              return (
                                <motion.circle
                                  key={`pulse-center-out-${i}`}
                                  cx={150}
                                  cy={150}
                                  r={4 + Math.random() * 3}
                                  fill="#fff"
                                  initial={{ scale: 0 }}
                                  animate={{ 
                                    scale: [0, 1, 0],
                                    cx: [150, endX],
                                    cy: [150, endY],
                                  }}
                                  transition={{ 
                                    duration: 2,
                                    delay: i * 3 + 6 + Math.random() * 2,
                                    repeat: Infinity,
                                    repeatDelay: 7 + Math.random() * 3
                                  }}
                                />
                              );
                            })}
                            
                            {/* Binary data bits floating around */}
                            {Array.from({ length: 20 }).map((_, i) => {
                              const x = 50 + Math.random() * 200;
                              const y = 50 + Math.random() * 200;

                              return (
                                <motion.text
                                  key={`bit-${i}`}
                                  x={x}
                                  y={y}
                                  fill="rgba(255,255,255,0.4)"
                                  fontSize="8"
                                  fontFamily="monospace"
                                  initial={{ opacity: 0 }}
                                  animate={{ 
                                    opacity: [0, 0.6, 0],
                                  }}
                                  transition={{
                                    duration: 3,
                                    delay: Math.random() * 5,
                                    repeat: Infinity,
                                    repeatDelay: Math.random() * 5,
                                  }}
                                >
                                  {Math.random() > 0.5 ? "1" : "0"}
                                </motion.text>
                              );
                            })}
                          </svg>
                          
                          {/* Memory label */}
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 2 }}
                            style={{
                              position: 'absolute',
                              bottom: '10%',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              background: 'rgba(0,0,0,0.7)',
                              backdropFilter: 'blur(10px)',
                              padding: '8px 16px',
                              borderRadius: '30px',
                              border: `1px solid ${feature.color}30`,
                              color: feature.color,
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                          >
                            Continuous Learning System
                          </motion.div>
                        </div>
                      )}
                      
                      {index === 2 && (
                        <div className="insights-visual" style={{ position: 'relative', height: '100%' }}>
                          <div className="ai-core">
                            <div className="core-pulse" style={{ 
                              borderColor: feature.color,
                              boxShadow: `0 0 30px ${feature.color}50`,
                              position: 'absolute',
                              width: '80px',
                              height: '80px',
                              borderRadius: '50%',
                              border: `2px solid ${feature.color}`,
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              animation: 'pulse 3s infinite'
                            }}></div>
                            <div className="core-center" style={{ 
                              background: feature.gradient,
                              position: 'absolute',
                              width: '60px',
                              height: '60px',
                              borderRadius: '50%',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '1.5rem',
                              color: 'white',
                              zIndex: 5
                            }}>
                              <i className={feature.icon}></i>
                            </div>
                            
                            <div className="connections" style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%'
                            }}>
                              {[...Array(10)].map((_, i) => {
                                const angle = (i * 36) * Math.PI / 180;
                                const x1 = 50 + 20 * Math.cos(angle);
                                const y1 = 50 + 20 * Math.sin(angle);
                                const x2 = 50 + 100 * Math.cos(angle);
                                const y2 = 50 + 100 * Math.sin(angle);
                                
                                return (
                                  <motion.div 
                                    key={`connector-${i}`}
                                    initial={{ width: 0, opacity: 0 }}
                                    animate={{ width: '100%', opacity: 1 }}
                                    transition={{ duration: 0.8, delay: 0.05 * i }}
                                    style={{
                                      position: 'absolute',
                                      height: '2px',
                                      left: `${x1}%`,
                                      top: `${y1}%`,
                                      width: `${Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))}%`,
                                      transform: `rotate(${angle * 180 / Math.PI}deg)`,
                                      transformOrigin: 'left center',
                                      background: `linear-gradient(to right, ${feature.color}, transparent)`
                                    }}
                                  ></motion.div>
                                );
                              })}
                            </div>
                            
                            <div className="insight-nodes" style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 3
                            }}>
                              {[
                                { top: '15%', left: '8%', text: 'Real-time Recommendations', delay: 0.8 },
                                { top: '75%', left: '12%', text: 'Proactive Suggestions', delay: 1.0 },
                                { top: '25%', right: '8%', text: 'Interactive Insights', delay: 1.2 },
                                { top: '65%', right: '12%', text: 'Strategy Enhancement', delay: 1.4 },
                              ].map((node, i) => (
                                <motion.div
                                  key={`insight-${i}`}
                                  initial={{ opacity: 0, scale: 0.6 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{ delay: node.delay, duration: 0.5 }}
                                  style={{
                                    position: 'absolute',
                                    top: node.top,
                                    left: node.left,
                                    right: node.right,
                                    background: 'rgba(15, 16, 20, 0.8)',
                                    backdropFilter: 'blur(4px)',
                                    border: `1px solid ${feature.color}`,
                                    borderRadius: '8px',
                                    padding: '6px 12px',
                                    fontSize: '0.85rem',
                                    fontWeight: 600,
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <span style={{ color: feature.color }}>{node.text}</span>
                                </motion.div>
                              ))}
                            </div>
                          </div>
                          
                          <style jsx>{`
                            @keyframes pulse {
                              0% { transform: translate(-50%, -50%) scale(1); opacity: 0.5; }
                              50% { transform: translate(-50%, -50%) scale(1.5); opacity: 0.1; }
                              100% { transform: translate(-50%, -50%) scale(1); opacity: 0.5; }
                            }
                          `}</style>
                        </div>
                      )}
                    </motion.div>
                  )
                ))}
              </AnimatePresence>
            </div>
          </Col>
          
          {/* Right column - Stacked Feature Cards */}
          <Col lg={6}>
            <div className="feature-cards-stack">
              {features.map((feature, index) => (
                <motion.div
                  key={`card-${feature.id}`}
                  className={`feature-card ${activeFeature === index ? 'active' : ''}`}
                  variants={cardVariants}
                  initial="inactive"
                  animate={activeFeature === index ? "active" : "inactive"}
                  onClick={() => setActiveFeature(index)}
                  style={{
                    background: 'rgba(20, 21, 30, 0.7)',
                    backdropFilter: 'blur(15px)',
                    borderRadius: '16px',
                    border: `1px solid rgba(${feature.color.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')}, ${activeFeature === index ? 0.5 : 0.2})`,
                    boxShadow: activeFeature === index ? 
                      `0 10px 30px rgba(0, 0, 0, 0.3), 0 0 30px rgba(${feature.color.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')}, 0.15)` : 
                      'none',
                    padding: '16px 20px',
                    marginBottom: '15px',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  <div className="card-content">
                    <div className="feature-header" style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      marginBottom: '10px' 
                    }}>
                      <i 
                        className={feature.icon} 
                        style={{ 
                          fontSize: '1.5rem', 
                          marginRight: '15px',
                          background: feature.gradient,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      ></i>
                      <h3 style={{ 
                        fontSize: '1.6rem',
                        margin: 0,
                        background: feature.gradient,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}>{feature.title}</h3>
                    </div>
                    
                    <p style={{ 
                      fontSize: '1rem', 
                      lineHeight: '1.5', 
                      color: activeFeature === index ? '#fff' : 'rgba(255,255,255,0.7)',
                      margin: 0
                    }}>
                      {feature.description}
                    </p>
                  </div>
                  
                  {/* Highlight bar */}
                  <div style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '4px',
                    background: activeFeature === index ? feature.gradient : 'transparent',
                    borderRadius: '3px 0 0 3px'
                  }}></div>
                </motion.div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      
      <style jsx>{`
        .features-section {
          position: relative;
          overflow: hidden;
          padding: 60px 0;
        }
        
        .features-bg-grid {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(rgba(255,255,255,0.05) 1px, transparent 1px),
                            linear-gradient(90deg, rgba(255,255,255,0.05) 1px, transparent 1px);
          background-size: 40px 40px;
          z-index: 0;
        }
        
        .particle-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
        
        .feature-card {
          transform-origin: center center;
        }
        
        .feature-card.active {
          z-index: 2;
        }
        
        .feature-cards-stack {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      `}</style>
    </section>
  );
};

Features.propTypes = {
  feature1Title: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1Icon: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2Description: PropTypes.string,
  feature2Icon: PropTypes.string,
  feature3Title: PropTypes.string,
  feature3Description: PropTypes.string,
  feature3Icon: PropTypes.string,
};

export default Features;
