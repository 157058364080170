// src/components/public/FAQ_Page.js
// Component to show list of frequently asked questions

import React, { useEffect } from "react";
import { Accordion, Container} from "react-bootstrap";
import faqs from "./data/faq";
import Footer from "./Footer";

const FAQPage = ({ numToShow }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    const displayedFaqs = faqs.slice(0, numToShow);

    return (
        <div className="faq-page">
            <Container>
                <h1 className="mb-4">Frequently Asked Questions</h1>
                <Accordion defaultActiveKey="0">
                    {displayedFaqs.map(faq => (
                        <Accordion.Item eventKey={faq.id.toString()} key={faq.id}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>
                                {faq.answer}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Container>
            <Footer />
        </div>
    );
};

export default FAQPage;