import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useRef } from 'react';

const ThreeCApproach = () => {
    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, { once: false, amount: 0.3 });
    const controls = useAnimation();
    
    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [isInView, controls]);

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.8,
                ease: [0.215, 0.61, 0.355, 1]
            }
        })
    };

    const circleVariants = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: {
            pathLength: 1,
            opacity: 0.8,
            transition: {
                pathLength: { duration: 2, ease: "easeInOut" },
                opacity: { duration: 1 }
            }
        }
    };

    const lineVariants = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => ({
            pathLength: 1,
            opacity: 0.7,
            transition: {
                delay: i * 0.3,
                pathLength: { duration: 1.5, ease: "easeOut" },
                opacity: { duration: 0.8 }
            }
        })
    };

    const titleVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    const cValues = [
        {
            title: "Customer",
            description: "Our customers are our priority, and customer service is sacred. We measure our success with that of our customers.",
            icon: "bi-people-fill",
            color: "var(--ai-teal)",
            rotation: 360,
            delay: 0
        },
        {
            title: "Community",
            description: "Small and medium-sized businesses are the backbone of any economy. We support them by fostering a culture where data becomes a natural part of daily business, empowering growth and profitability.",
            icon: "bi-globe",
            color: "var(--ai-purple)",
            rotation: -360,
            delay: 0.2
        },
        {
            title: "Company",
            description: "We aim to build a company grounded in ethics and the greater good — one that our customers can trust, delivering consistent value for mutual success.",
            icon: "bi-building",
            color: "var(--ai-blue)",
            rotation: 360,
            delay: 0.4
        }
    ];

    return (
        <section className="py-5 my-5 values-section" ref={sectionRef}>
            <Container>
                <motion.div
                    className="text-center mb-5"
                    initial="hidden"
                    animate={controls}
                    variants={titleVariants}
                >
                    <h2 className="mb-3 position-relative d-inline-block">
                        Our Values: <span style={{ color: 'var(--ai-teal)' }}>The 3C Approach</span>
                        <motion.div 
                            className="title-underline-animated"
                            initial={{ width: 0 }}
                            animate={isInView ? { width: '100%' } : { width: 0 }}
                            transition={{ duration: 1, delay: 0.5 }}
                            style={{
                                position: 'absolute',
                                bottom: '-10px',
                                left: 0,
                                height: '3px',
                                background: 'var(--ai-gradient-1)',
                                borderRadius: '2px'
                            }}
                        />
                    </h2>
                    <p className="mb-4 mx-auto" style={{ maxWidth: '700px' }}>
                        Our 3C Approach shapes everything we do, forming the foundation of our business philosophy.
                        Each pillar represents a core commitment that guides our decisions and actions.
                    </p>
                </motion.div>
                
                <div className="three-c-container position-relative">
                    {/* Hexagon background pattern */}
                    <div className="hexagon-pattern">
                        {[...Array(20)].map((_, i) => (
                            <motion.div
                                key={`hex-${i}`}
                                className="hexagon"
                                initial={{ opacity: 0, scale: 0 }}
                                animate={isInView ? { 
                                    opacity: 0.03 + (Math.random() * 0.04), 
                                    scale: 0.5 + (Math.random() * 0.5),
                                    rotate: Math.random() * 30
                                } : { opacity: 0, scale: 0 }}
                                transition={{ 
                                    duration: 0.8, 
                                    delay: 0.1 * i,
                                    ease: "easeOut"
                                }}
                                style={{
                                    left: `${Math.random() * 100}%`,
                                    top: `${Math.random() * 100}%`,
                                    background: i % 3 === 0 ? 'var(--ai-teal)' : i % 3 === 1 ? 'var(--ai-purple)' : 'var(--ai-blue)'
                                }}
                            />
                        ))}
                    </div>

                    {/* Central connecting element */}
                    <div className="central-connector d-none d-lg-block">
                        <motion.div 
                            className="connector-circle"
                            initial={{ scale: 0, opacity: 0 }}
                            animate={isInView ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
                            transition={{ duration: 1, delay: 0.8 }}
                        >
                            <svg width="100%" height="100%" viewBox="0 0 200 200">
                                <defs>
                                    <linearGradient id="circleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor="var(--ai-teal)" />
                                        <stop offset="50%" stopColor="var(--ai-purple)" />
                                        <stop offset="100%" stopColor="var(--ai-blue)" />
                                    </linearGradient>
                                    <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                                        <feGaussianBlur stdDeviation="5" result="blur" />
                                        <feComposite in="SourceGraphic" in2="blur" operator="over" />
                                    </filter>
                                </defs>
                                
                                <motion.circle 
                                    cx="100" 
                                    cy="100" 
                                    r="80"
                                    fill="none"
                                    stroke="url(#circleGradient)"
                                    strokeWidth="2"
                                    strokeDasharray="5,5"
                                    variants={circleVariants}
                                    initial="hidden"
                                    animate={controls}
                                    filter="url(#glow)"
                                />
                                
                                <motion.circle 
                                    cx="100" 
                                    cy="100" 
                                    r="60"
                                    fill="none"
                                    stroke="url(#circleGradient)"
                                    strokeWidth="1.5"
                                    strokeDasharray="3,3"
                                    variants={circleVariants}
                                    initial="hidden"
                                    animate={controls}
                                    filter="url(#glow)"
                                    style={{ opacity: 0.6 }}
                                />
                                
                                <motion.text 
                                    x="100" 
                                    y="105" 
                                    textAnchor="middle" 
                                    fill="white"
                                    style={{ 
                                        fontFamily: 'sans-serif', 
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                                    transition={{ duration: 1, delay: 1.2 }}
                                >
                                    3C
                                </motion.text>
                            </svg>
                            
                            {/* Animated particles */}
                            {[...Array(3)].map((_, i) => (
                                <motion.div
                                    key={`particle-${i}`}
                                    className="connector-particle"
                                    initial={{ scale: 0 }}
                                    animate={isInView ? { 
                                        scale: [0, 1, 0],
                                        rotate: 360 * (i % 2 === 0 ? 1 : -1)
                                    } : { scale: 0 }}
                                    transition={{
                                        scale: {
                                            duration: 2,
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            delay: i * 0.7
                                        },
                                        rotate: {
                                            duration: 8,
                                            repeat: Infinity,
                                            ease: "linear"
                                        }
                                    }}
                                    style={{
                                        background: i === 0 ? 'var(--ai-teal)' : i === 1 ? 'var(--ai-purple)' : 'var(--ai-blue)'
                                    }}
                                />
                            ))}
                        </motion.div>
                    </div>

                    {/* 3C Cards in a triangle formation for larger screens */}
                    <Row className="justify-content-center">
                        {cValues.map((cValue, index) => (
                            <Col lg={4} md={6} className="mb-5" key={`c-${index}`}>
                                <motion.div 
                                    className="c-card-modern"
                                    custom={index}
                                    variants={cardVariants}
                                    initial="hidden"
                                    animate={controls}
                                    whileHover={{ 
                                        scale: 1.03, 
                                        boxShadow: "0 20px 30px rgba(0, 0, 0, 0.25)",
                                        y: -10
                                    }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div className="card-glow" style={{ background: cValue.color }}></div>
                                    <div className="c-card-content">
                                        <div className="c-icon-container">
                                            <motion.div 
                                                className="c-icon-modern"
                                                animate={{ rotate: cValue.rotation }}
                                                transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
                                                style={{ background: `linear-gradient(135deg, ${cValue.color}, rgba(255,255,255,0.2))` }}
                                            >
                                                <i className={`bi ${cValue.icon}`}></i>
                                                <div className="icon-pulse" style={{ borderColor: cValue.color }}></div>
                                            </motion.div>
                                        </div>
                                        <h3 className="c-title">
                                            <span style={{ color: 'var(--accent-orange)' }}>C</span>{cValue.title.substring(1)}
                                        </h3>
                                        <div className="c-divider" style={{ background: cValue.color }}></div>
                                        <p className="c-description">{cValue.description}</p>
                                        
                                        {/* Decorative elements */}
                                        <div className="corner-element top-left"></div>
                                        <div className="corner-element top-right"></div>
                                        <div className="corner-element bottom-left"></div>
                                        <div className="corner-element bottom-right"></div>
                                    </div>
                                </motion.div>
                                
                                {/* Connection lines between cards - only visible on larger screens */}
                                {index < 2 && (
                                    <div className="connection-line d-none d-lg-block">
                                        <svg width="100%" height="80" viewBox="0 0 200 80">
                                            <motion.path 
                                                d={index === 0 ? "M0,20 C50,80 150,80 200,20" : "M0,60 C50,0 150,0 200,60"}
                                                stroke={cValue.color}
                                                strokeWidth="2"
                                                strokeDasharray="5,5"
                                                fill="none"
                                                custom={index}
                                                variants={lineVariants}
                                                initial="hidden"
                                                animate={controls}
                                            />
                                        </svg>
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
            
            {/* Add CSS for the new sophisticated design */}
            <style jsx>{`
                .values-section {
                    position: relative;
                    overflow: hidden;
                }
                
                .three-c-container {
                    position: relative;
                    padding: 3rem 0;
                }
                
                .hexagon-pattern {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
                
                .hexagon {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
                }
                
                .central-connector {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
                
                .connector-circle {
                    position: relative;
                    width: 120px;
                    height: 120px;
                }
                
                .connector-particle {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: calc(50% - 5px);
                    left: calc(50% - 5px);
                    filter: blur(2px);
                }
                
                .c-card-modern {
                    position: relative;
                    background: rgba(25, 25, 35, 0.7);
                    backdrop-filter: blur(10px);
                    border-radius: 16px;
                    padding: 2.5rem 1.5rem;
                    height: 100%;
                    min-height: 320px;
                    overflow: hidden;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
                    transition: all 0.3s ease;
                    z-index: 2;
                }
                
                .card-glow {
                    position: absolute;
                    top: -50px;
                    right: -50px;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    filter: blur(40px);
                    opacity: 0.3;
                    z-index: -1;
                }
                
                .c-card-content {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }
                
                .c-icon-container {
                    margin-bottom: 1.5rem;
                }
                
                .c-icon-modern {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                }
                
                .c-icon-modern i {
                    font-size: 2rem;
                    color: white;
                }
                
                .icon-pulse {
                    position: absolute;
                    top: -5px;
                    left: -5px;
                    right: -5px;
                    bottom: -5px;
                    border-radius: 50%;
                    border: 2px solid;
                    opacity: 0;
                    animation: pulse 2s infinite;
                }
                
                @keyframes pulse {
                    0% {
                        transform: scale(1);
                        opacity: 0.7;
                    }
                    70% {
                        transform: scale(1.2);
                        opacity: 0;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 0;
                    }
                }
                
                .c-title {
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                    font-weight: 600;
                }
                
                .c-divider {
                    width: 40px;
                    height: 3px;
                    margin-bottom: 1.5rem;
                    border-radius: 2px;
                }
                
                .c-description {
                    font-size: 1rem;
                    line-height: 1.6;
                    color: rgba(255, 255, 255, 0.8);
                }
                
                .corner-element {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    border-style: solid;
                    border-color: rgba(255, 255, 255, 0.1);
                    border-width: 0;
                }
                
                .top-left {
                    top: 15px;
                    left: 15px;
                    border-top-width: 2px;
                    border-left-width: 2px;
                }
                
                .top-right {
                    top: 15px;
                    right: 15px;
                    border-top-width: 2px;
                    border-right-width: 2px;
                }
                
                .bottom-left {
                    bottom: 15px;
                    left: 15px;
                    border-bottom-width: 2px;
                    border-left-width: 2px;
                }
                
                .bottom-right {
                    bottom: 15px;
                    right: 15px;
                    border-bottom-width: 2px;
                    border-right-width: 2px;
                }
                
                .connection-line {
                    position: absolute;
                    width: 100%;
                    height: 80px;
                    z-index: 1;
                }
                
                @media (max-width: 991px) {
                    .c-card-modern {
                        margin-bottom: 2rem;
                    }
                }
            `}</style>
        </section>
    );
};

export default ThreeCApproach; 