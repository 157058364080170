import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../custom.css';
import Footer from './Footer';
import ContactUsForm from './ContactUsForm';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ThreeCApproach from './ThreeCApproach';
import AiVisualization from './AiVisualization';

function About() {
    // State for handling modal visibility
    const [showModal, setShowModal] = useState(false);
    
    const heroRef = useRef(null);

    // Functions to open and close the modal
    const handleCloseModal = () => setShowModal(false);

    // Initialize AOS animation library
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            mirror: true,
        });
    }, []);

    return (
        <div className='modern-ai-theme about-us-page'>
            {/* Modern grid background with lines */}
            <div className="grid-lines"></div>
            
            {/* Hero section */}
            <section className="modern-hero" ref={heroRef}>
                <div className="hero-blob"></div>
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={6} md={12} className="hero-content order-lg-1 mb-4 mb-lg-0">
                            <motion.div
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                            >
                                <h2 className="mb-4">Who Are <span style={{ color: 'var(--ai-teal)' }}>We?</span></h2>
                                <p className="mb-4">
                                    We are a team that lives and breathes data. Our goal is to empower small and medium-sized businesses by turning data into actionable insights, making AI accessible for all, not just big corporations.
                                </p>
                            </motion.div>
                        </Col>
                        <Col lg={6} md={12} className="order-lg-2">
                            <AiVisualization />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mission Section */}
            <section className="py-5 my-5">
                <Container>
                    <Row className="align-items-center justify-content-center text-center">
                        <Col xs={12} md={10} lg={8} className="glass-card" data-aos="fade-up">
                            <div className="section-title-container">
                                <h2>Our <span style={{ color: 'var(--ai-teal)' }}>Mission</span></h2>
                                <div className="title-underline"></div>
                            </div>
                            <p>
                                Our mission is to empower small businesses with the wisdom of enterprise-grade intelligence. We blend your internal business data with external market signals through our StrataMemory™ engine, turning complexity into clarity. While other tools merely analyze the past, ARAMA serves as your 24/7 AI business advisor, delivering strategic foresight and preserving your organizational knowledge so you can focus on what truly matters: growing your business and staying ahead of the competition.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Values Section - Enhanced 3C Approach */}
            <ThreeCApproach />

            {/* Why Choose Us Section */}
            <section className="py-5 my-5">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} md={12} className="mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="glass-card h-100">
                                <h2 className="mb-4">Why Choose Us?</h2>
                                <p>
                                    Unlike traditional analytics that only show what happened, ARAMA tells you what to do next. Our AI business advisor combines your unique business context with market intelligence to provide guidance that gets smarter over time.
                                </p>
                                <p className="mt-4">
                                    With our proprietary StrataMemory™ engine, ARAMA captures your business journey and preserves critical organizational insights, building a knowledge foundation that grows alongside your company. The result? Smarter operations, reduced costs, and increased revenue, all without needing a dedicated data team.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} md={12} className="text-center" data-aos="fade-left">
                            <div className="glass-card">
                                <div className="floating-image-container m-0">
                                    <img src="https://cdn.statically.io/img/i.postimg.cc/0ySGrKfm/trust.jpg" 
                                        alt="Why Choose Us?" 
                                        className="img-fluid modern-image" 
                                        style={{ borderRadius: '8px', maxWidth: '100%' }}
                                    />
                                    <div className="gradient-overlay"></div>
                                    
                                    {/* Animated elements */}
                                    <motion.div 
                                        className="floating-dots dot1"
                                        style={{ background: 'var(--ai-teal)' }}
                                    ></motion.div>
                                    <motion.div 
                                        className="floating-dots dot3"
                                        style={{ background: 'var(--ai-purple)' }}
                                    ></motion.div>
                                    
                                    {/* Circuit pattern overlay */}
                                    <motion.div 
                                        className="circuit1"
                                        style={{
                                            position: 'absolute',
                                            width: '80px',
                                            height: '80px',
                                            top: '10%',
                                            right: '10%',
                                            opacity: 0.4,
                                            zIndex: 2
                                        }}
                                        animate={{ rotate: 360 }}
                                        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
                                    >
                                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }}>
                                            <path d="M10,10 L90,10 L90,90 L10,90 Z" fill="none" stroke="var(--ai-teal)" strokeWidth="1"/>
                                            <path d="M30,30 L70,30 M30,50 L70,50 M30,70 L70,70" fill="none" stroke="var(--ai-teal)" strokeWidth="1"/>
                                            <path d="M30,30 L30,70 M50,30 L50,70 M70,30 L70,70" fill="none" stroke="var(--ai-teal)" strokeWidth="1"/>
                                        </svg>
                                    </motion.div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Founder Section */}
            <section className="py-5 my-5 founder-section">
                <Container>
                    <Row className="justify-content-center text-center mb-4">
                        <Col lg={8}>
                            <h2 className="mb-3">Meet The <span style={{ color: 'var(--ai-teal)' }}>Team</span></h2>
                        </Col>
                    </Row>
                    <Row className="align-items-center mb-5">
                        <Col lg={4} md={6} className="mb-4 mb-lg-0 mx-auto" data-aos="fade-right">
                            <div className="founder-image-container text-center">
                                <div className="avatar-circle">
                                    <img 
                                        src="/founder.jpg" 
                                        alt="Nikhil - Founder & CEO" 
                                        style={{ 
                                            width: '10rem', 
                                            height: '10rem', 
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <div className="founder-glow"></div>
                                </div>
                                <h3 className="mt-3" style={{ color: 'var(--ai-teal)' }}>Nikhil</h3>
                                <p className="founder-title">Founder & CEO</p>
                            </div>
                        </Col>
                        <Col lg={8} md={12} data-aos="fade-left">
                            <div className="glass-card founder-bio">
                                <p>
                                    Nikhil specializes in building AI/ML-driven solutions that deliver measurable business impact. His expertise in predictive analytics helps organizations optimize decision-making, eliminate bias, and unlock new growth opportunities.
                                </p>
                                <p>
                                    As the founder, he ensures ARAMA's solutions embody our 3C principles: prioritizing Customer success through data-driven insights, fostering Community growth by empowering small and medium businesses, and building a Company culture grounded in ethical AI practices and sustainable innovation.
                                </p>
                                <div className="founder-signature">
                                    <motion.div 
                                        className="signature-element"
                                        initial={{ width: 0 }}
                                        whileInView={{ width: '120px' }}
                                        transition={{ duration: 1 }}
                                        viewport={{ once: true }}
                                    ></motion.div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className="align-items-center">
                        <Col lg={4} md={6} className="mb-4 mb-lg-0 mx-auto order-lg-2" data-aos="fade-left">
                            <div className="founder-image-container text-center">
                                <div className="avatar-circle">
                                    <img 
                                        src="/anirudh_formal.jpg" 
                                        alt="Anirudh - Data Scientist" 
                                        style={{ 
                                            width: '10rem', 
                                            height: '10rem', 
                                            objectFit: 'cover',
                                            objectPosition: 'center 0%',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <div className="founder-glow"></div>
                                </div>
                                <h3 className="mt-3" style={{ color: 'var(--ai-teal)' }}>Anirudh</h3>
                                <p className="founder-title">Data Scientist</p>
                            </div>
                        </Col>
                        <Col lg={8} md={12} className="order-lg-1" data-aos="fade-right">
                            <div className="glass-card founder-bio">
                                <p>
                                    Anirudh is a data scientist passionate about building intelligent systems that simplify complexity and drive real-world impact. His work blends time series forecasting, NLP, and MLOps to deliver scalable AI solutions that help businesses automate decisions and adapt to change.
                                </p>
                                <p>
                                    At ARAMA, he leads the development of our models and decision intelligence engine. His focus is on making AI accessible and actionable for small and medium businesses, aligning with our 3C principles by enabling smarter Customer decisions, strengthening Community resilience, and advancing Company innovation through responsible, efficient AI.
                                </p>
                                <div className="founder-signature">
                                    <motion.div 
                                        className="signature-element"
                                        initial={{ width: 0 }}
                                        whileInView={{ width: '120px' }}
                                        transition={{ duration: 1 }}
                                        viewport={{ once: true }}
                                    ></motion.div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />

            {/* Contact Us Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="w-100 text-center">Get Your AI Business Advisor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default About;
